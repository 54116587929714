<template>
  <div>
    <AddEditExpense
      ref="addEditExpenseRef"
      :is-add-new-expense-sidebar-active.sync="isAddNewExpenseSidebarActive"
      :value="dataToBeEdited"
      :is-edit="isEdit"
      :prop-id="propId"
      :room-id="roomId"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="!isViewOnly"
              class="d-flex align-items-center justify-content-end"
            >

              <b-button
                variant="primary"
                @click="addExpense"
              >
                <span class="text-nowrap">Add New Expense</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        id="expense-overlay-background"
        :show="isLoading"
        variant="blur"
        :opacity="0.5"
        blur="2px"
        rounded="sm"
      >
        <b-card
          class="m-1"
          no-body
        >
          <b-card-body class="p-0">

            <section class="todo-application-2">

              <div class="content-todooo">

                <div style="height: inherit">

                  <div class="todo-app-list">
                    <div class="todo-task-list-wrapper list-group scroll-area">

                      <ul class="todo-task-list media-list">
                        <ExpenseCard
                          v-for="(expense, index) in expenses"
                          :key="keyChange + '_' + index"
                          :value="expense"
                          @onClick="onClick"
                        />
                      </ul>
                    </div>
                  </div>

                </div>

              </div>

            </section>
          </b-card-body>
        </b-card>
      </b-overlay>

      <div class="mx-2 mb-2">

        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BButton, BRow, BPagination, BOverlay, BCardBody,

} from 'bootstrap-vue'
import { mapState } from 'vuex'
import AddEditExpense from './components/AddEditExpense.vue'
import ExpenseCard from './components/ExpenseCard.vue'

import { LogicStateStatus } from '@/libs/ajax'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { defaultExpenseModel } from '@/api/defaultModel'
import { kFakeNumber } from '@/assets/helpers'
import { ExpenseCategory, expenseModelField } from '@/api/field_model'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    AddEditExpense,
    BOverlay,
    BCard,
    ExpenseCard,
    BCardBody,
    BButton,
  },
  mixins: [AuthMixin],
  props: {
    propId: {
      type: Number,
      default: kFakeNumber,
    },
    roomId: {
      type: Number,
      default: kFakeNumber,
    },
  },
  data() {
    return {
      currentPage: 1,
      keyChange: 0,
      isAddNewExpenseSidebarActive: false,
      perPage: 13,
      isEdit: false,
      dataToBeEdited: null,
    }
  },
  computed: {
    from() {
      return this.perPage * (this.currentPage - 1) + (this.expenses.length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.expenses.length
    },
    of() {
      return this.total
    },
    ...mapState({
      expenses: state => state.expenses.expenses,
      total: state => state.expenses.total,
      isLoading: state => state.expenses.logicStateStatus === LogicStateStatus.loading,
    }),
  },
  watch: {
    currentPage() {
      this.getExpenses()
    },
  },
  mounted() {
    if (this.propId !== kFakeNumber) {
      this.$store.commit('expenses/UPDATE_EXPENSES', [])
      this.getExpenses()
    }
  },
  methods: {
    addExpense() {
      this.isEdit = false
      this.$refs.addEditExpenseRef.expenseModel = { ...defaultExpenseModel }
      this.$refs.addEditExpenseRef.category = null
      this.isAddNewExpenseSidebarActive = true
    },
    onClick(value) {
      this.isEdit = true
      this.dataToBeEdited = { ...value }
      this.$refs.addEditExpenseRef.expenseModel = { ...value }
      this.$refs.addEditExpenseRef.category = {
        value: value[expenseModelField.category],
        title: new ExpenseCategory().getLabel(value[expenseModelField.category]),
      }
      this.isAddNewExpenseSidebarActive = true
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    getExpenses() {
      const payload = {
        page: this.currentPage,
        propId: this.roomId && this.roomId !== kFakeNumber ? null : this.propId,
        roomId: this.roomId,
        notify: this.showToast,
      }
      this.$store.dispatch('expenses/getExpenses', payload)
        .then(() => { this.keyChange += 1 })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo-2.scss";
</style>

import { mapGetters } from 'vuex'
import Zemen from 'zemen'
import { dateLocalTypeField } from '@/api/field_model'
// import { amharic } from '@/assets/et'
import { toGregorian, toEthiopian } from '@/@core/utils/ehtiopia_date'

const DateLocalMixin = {
  computed: {
    ...mapGetters('auth', [
      'dateLocalType',
    ]),
    isEthiopianCalender() {
      return this.dateLocalType === dateLocalTypeField.ethiopian
    },
    todayEtDate() {
      return new Zemen()
    },
  },
  methods: {
    convertToGregorian(v) {
      return toGregorian(v)
    },
    convertToEthiopian(v) {
      return toEthiopian(v)
    },
    formatDateToEt(v) {
      try {
        const z = Zemen.toEC(v)
        return this.isEthiopianCalender ? z.format('MMMM-DD-YYYY') : v
      } catch (error) {
        return v
      }
    },
  },
  data() {
    return {
      // flatPickerConfig: {
      //   locale: amharic,
      //   // minDate: '2019-01-01',
      // },
    }
  },
  // watch: {
  //   dateLocalType(v) {
  //     if (v === dateLocalTypeField.ethiopian) {
  //       this.flatPickerConfig = {
  //         locale: amharic,
  //       }
  //     } else {
  //       this.flatPickerConfig = {
  //         locale: null,
  //       }
  //     }
  //   },
  // },
}

export default DateLocalMixin

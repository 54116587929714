<template>
  <el-cascader
    v-model="date"
    placeholder="Select Date"
    :options="options"
    @change="handleChange"
  />
</template>

<script>
import Zemen from 'zemen'
import { amharic } from '@/assets/et'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      options: [],
      date: [],
    }
  },
  watch: {
    value(v) {
      this.date = v
    },
  },
  mounted() {
    // const zare = new Zemen()
    const months = amharic.months.shorthand
    this.options = this.generateArrayOfYears().map(v => ({
      value: v,
      label: `${v}`,
      children: months.map((h, i) => ({
        value: (i + 1),
        label: h,
        children: this.getDates(v, (i + 1)),
      })),
    })).reverse()
  },
  methods: {
    getDates(year, month) {
      const yearr = year + (year < 0 ? 1 : 0) // No year zero
      const isLeap = yearr % 4 === 3 || yearr % 4 === -1
      // eslint-disable-next-line no-nested-ternary
      const monthNumber = (month === 13 ? (isLeap ? 6 : 5) : 30)
      return Array.from({ length: monthNumber }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      }))
    },
    generateArrayOfYears() {
      const zare = new Zemen()
      const max = zare.getFullYear() + 6
      const min = zare.getFullYear() - 4
      const years = []

      for (let i = max; i >= min; i -= 1) {
        years.push(i)
      }
      return years
    },
    handleChange(value) {
      this.$emit('change', value)
      // console.log(value)
    },
  },
}
</script>

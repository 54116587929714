<template>
  <input-model
    ref="inputModalRef"
    @onInput="onGenerate"
  />
</template>

<script>
import InputModel from '../../../components/inputModal.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    InputModel,
  },
  data() {
    return {
      update: false,
    }
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },

    onGenerate(v) {
      const payload = {
        update: this.update,
        numberOfUnit: v,
        notify: this.showToast,
        swal: this.$swal,
      }
      this.$store.commit('rooms/RESET_FILTER')
      this.$store.dispatch('generateRooms/generateRoom', payload)
        .then(() => { })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style scoped>

</style>

<template>

  <li
    class="todo-item"
    @click="onClick"
  >
    <div class="todo-title-wrapper">
      <div class="todo-title-area">
        <div class="title-wrapper">
          <span class="todo-title">{{ category || '~' }}</span>

        </div>

      </div>

      <div class="todo-item-action">
        <div class="badge-wrapper mr-1" />
        <small class="text-nowrap text-muted mr-1">{{ formatDate(value.dD, { month: 'short', day: 'numeric'}) }}</small>
      </div>
    </div>
    <p
      class="text-truncate mb-0"
    >
      {{ value[expenseModelField.note] }}
    </p>
  </li>
</template>

<script>
import { formatDate, avatarText } from '@core/utils/filter'
import { ExpenseCategory, expenseModelField } from '@/api/field_model'

const expenseCategory = new ExpenseCategory()

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      // Filters
      formatDate,
      avatarText,
      expenseModelField,
    }
  },
  computed: {
    category() {
      return expenseCategory.getLabel(this.value[expenseModelField.category])
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

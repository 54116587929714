<template>
  <b-sidebar
    v-if="show"
    id="add-edit-room-sidebar"
    :visible="isAddEditPaymentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-payment-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Log payment
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Amount"
                label-for="amount"
              >
                <b-form-input
                  v-model="historyModel[roomHistoriesModelField.amount]"
                  autofocus
                  trim
                  placeholder="0.0"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <al-upload
                v-if="!isEdit"
                :action="`${baseURL}/global/uploadImage`"
                :max="1"
                @file-success="onReceiptImageSuccess"
              />
              <b-col
                v-else
                class="p-0 m-0"
              >
                <b-img
                  v-bind="imageMainProps"
                  :src="historyModel[roomHistoriesModelField.img]"
                />

                <al-upload
                  v-if="!isViewOnly"
                  ref="upload"
                  v-model="receiptImage"
                  :action="`${baseURL}/global/uploadImage`"
                  :max="1"
                  @file-success="onReceiptImageSuccess"
                >
                  <al-upload-file
                    v-for="(file, i) in receiptImage"
                    :key="i"
                    :file="file"
                  />
                  <al-upload-btn :multiple="false">
                    <div>
                      <b-button
                        v-if="receiptImage.length <= 0"
                        block
                        class="mt-1"
                      >
                        Change Receipt Image
                      </b-button>
                    </div>
                  </al-upload-btn>
                </al-upload>

              </b-col>
            </b-col>
          </b-row>

          <b-form-group
            label="Payment Type"
            label-for="payment-type"
          >

            <v-select
              v-model="paymentType"
              label="title"
              :disabled="isEdit"
              label-for="payment-type"
              taggable
              :clearable="false"
              push-tags
              placeholder="Payment Type"
              :options="paymentTypes"
            />
          </b-form-group>

          <b-form-group
            v-if="isPaymentType"
            label="Payment Period"
            label-for="payment-period"
          >

            <v-select
              v-model="paymentDate"
              label="title"
              taggable
              :clearable="false"
              push-tags
              label-for="payment-period"
              placeholder="Payment Period"
              :options="paymentPeriods"
            />
          </b-form-group>

          <b-form-group
            label="Payment Status"
            label-for="payment-status"
          >

            <v-select
              v-model="paymentAction"
              label="title"
              label-for="payment-status"
              placeholder="Payment Period"
              :clearable="false"
              taggable
              push-tags
              :options="paymentActions"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div
            v-if="!isViewOnly"
            class="d-flex mt-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isLoading"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

          </div>
          <b-button
            v-if="isEdit && !isViewOnly"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            block
            class="mt-1"
            :disabled="isLoading"
            variant="danger"
            @click="onDelete"
          >
            Delete
          </b-button>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BCol, BRow, BImg,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import { nextTick, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import AlUpload from '../../components/upload/AlUpload.vue'
import AlUploadBtn from '../../components/upload/btn.vue'
import AlUploadFile from '../../components/upload/file.vue'
import {
  commonModelField, HistoryLabel, roomHistoriesModelField, roomHistoriesType,
} from '@/api/field_model'
import { defaultHistoryModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { LogicStateStatus } from '@/libs/ajax'
import { baseURL } from '@/libs/axios'
import AuthMixin from '@/mixins/authMixin'

const historyLabel = new HistoryLabel()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,

    BButton,
    BCol,
    BRow,
    AlUploadBtn,
    AlUploadFile,
    BImg,

    ValidationObserver,
    AlUpload,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  model: {
    prop: 'isAddEditPaymentSidebarActive',
    event: 'update:is-add-edit-payment-sidebar-active',
  },
  props: {
    isAddEditPaymentSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      roomHistoriesModelField,
      historyLabel,
      baseURL,
      imageMainProps: {
        style: 'height: 140px;width:140px;',
        fluid: true,
        thumbnail: true,
      },
      receiptImage: [],
      paymentTypes: historyLabel.paymentTypes(),
      paymentPeriods: [],
      paymentType: {
        title: historyLabel.getHistoryTypeLabel(historyLabel.payment),
        value: roomHistoriesType.payment,
      },
      paymentDate: null,
      paymentAction: {
        title: historyLabel.getActionLabel(historyLabel.paymentComplete),
        value: historyLabel.paymentComplete,
      },
      paymentActions: historyLabel.paymentActions(),
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.addEditPayment.logicStateStatus === LogicStateStatus.loading,
    }),
    isPaymentType() {
      return this.paymentType.value === roomHistoriesType.payment
    },
  },
  methods: {
    onDelete() {
      const payload = {
        historyModel: this.historyModel,
        notify: this.showToast,
      }
      this.$store.dispatch('addEditPayment/deletePayment', payload)
        .then(() => {
          this.$emit('update:is-add-edit-payment-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    onReceiptImageSuccess(v) {
      this.historyModel[roomHistoriesModelField.img] = v.response.data.url
      this.receiptImage = []
    },
    onSubmit() {
      const historyModel = { ...this.historyModel }
      if (this.paymentType.value === historyLabel.payment && (!this.paymentDate || !this.paymentDate.value)) {
        this.showToast('Error', 'Please select payment period to continue')
        return
      }
      historyModel[roomHistoriesModelField.type] = this.paymentType.value
      historyModel[roomHistoriesModelField.action] = this.paymentAction.value
      if (this.paymentType.value === historyLabel.payment) {
        historyModel[commonModelField.id] = this.paymentDate.value
        historyModel[roomHistoriesModelField.paymentDate] = this.paymentDate.title
      }
      // if (this.isEdit) {
      // }

      const payload = {
        historyModel,
        notify: this.showToast,
      }
      this.$store.dispatch(`addEditPayment/${this.isEdit ? 'updatePayment' : 'makePayment'}`, payload)
        .then(() => {
          this.reset()
          if (!this.isEdit) {
            this.$swal({
              position: 'bottom-end',
              icon: 'success',
              title: 'Successfully Log payment',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          this.$emit('update:is-add-edit-payment-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const historyModel = { ...defaultHistoryModel }
    const show = ref(true)

    const reset = () => {
      show.value = false
      nextTick(() => {
        show.value = true
      })
    }

    const resethistoryModel = () => {
      historyModel.value = JSON.parse(JSON.stringify(defaultHistoryModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resethistoryModel)

    return {
      historyModel,
      show,
      reset,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#add-edit-room-sidebar {
  width: 530px;
}
</style>

<template>

  <b-card no-body>

    <b-card-body>

      <!-- User Avatar & Action Buttons -->
      <div class="d-flex justify-content-start">
        <b-avatar
          :text="getRoomLabel"
          variant="secondary"
          size="104px"
          badge-top
          badge-offset="-0.5em"
          :badge-variant="iconColor"
          rounded
        >
          <template #badge>
            <feather-icon
              v-if="value.overDue"
              size="26"
              icon="XIcon"
            />
            <feather-icon
              v-if="value.dueOn"
              size="26"
              color="var(--white)"
              icon="CheckIcon"
            />
            <div
              v-if="isUnrented"
              style="fill: var(--white)"
              v-html="unrentedSvg"
            />
          </template>
        </b-avatar>
        <div
          v-if="!isUnrented"
          class="d-flex flex-column ml-2 w-100"
        >
          <div class="mb-1">
            <div class="d-flex justify-content-between">
              <h3 class="mb-0 font-weight-bold">
                {{ dueLabel }}
              </h3>
              <div
                v-if="value.isExpired"
                v-b-tooltip.hover.bottom="expiredTooltip"
                style="width: 25px; fill: red;"
                v-html="leaseExpiredSvg"
              />
            </div>
            <span
              v-b-tooltip.hover.bottom="'Due date'"
              class="card-text float-left"
            >{{ dueDateLabel }}</span>
          </div>
          <div
            class="d-flex flex-wrap w-100"
            style=" align-items: center;"
          >
            <b-badge
              v-b-tooltip.hover.top="'Start Date'"
              class="mr-1"
              style="color: #A7ABB3; padding: 5px; font-size: 15px;font-weight: 300;"
              variant="light-grey"
            >
              <div
                class="d-flex"
                style="text-align: center;
    justify-content: center;
    align-items: center;
    "
              >
                <feather-icon
                  size="25"
                  class="mr-1"
                  icon="CalendarIcon"
                />
                <span> {{ formatDateToEt(lease[leaseModelField.startDate]) }}</span>
              </div>
            </b-badge>
            <div class="divider-r" />
            <b-badge

              v-b-tooltip.hover.top="'End Date'"
              class="ml-1"
              style="color: #A7ABB3; padding: 5px; font-size: 15px;font-weight: 300;"
              variant="light-grey"
            >
              <div
                class="d-flex"
                style="text-align: center;
    justify-content: center;
    align-items: center;
    "
              >
                <feather-icon
                  size="25"
                  class="mr-1"
                  icon="CalendarIcon"
                />
                <span> {{ formatDateToEt(lease[leaseModelField.leaseEnd]) }}</span>
              </div>
            </b-badge>
          </div>
          <div
            class="d-flex flex-wrap mt-1 w-100"
            style="justify-content: space-between;"
          >
            <div>
              <span
                v-b-tooltip.hover.bottom="'Rent Due/Overdue'"
                class="card-text"
              >Br {{ !value.overDue ? lease[leaseModelField.rentAmount] : value.amountOverdue }}</span>
            </div>
            <div>
              <span
                v-b-tooltip.hover.bottom="'Payment Frequency'"
                class="card-text"
              >{{ getRoomPaymentFreq }}</span>
            </div>
          </div>
        </div>

      </div>

      <RoomActionButtons
        :value="value"
        @showRoomExpense="$emit('showRoomExpense')"
        @showRoomHistory="$emit('showRoomHistory')"
        @showRoomDetail="$emit('showRoomDetail')"
        @addEditRoom="$emit('addEditRoom')"
        @updateExistingLease="$emit('updateExistingLease')"
        @addNewLease="$emit('addNewLease')"
        @logPayment="$emit('logPayment')"
        @terminateAndMakeThisRoomAdUnrented="$emit('terminateAndMakeThisRoomAdUnrented')"
      />
    </b-card-body>

  </b-card>

</template>

<script>
/* eslint-disable no-nested-ternary */
import {
  BCard, BAvatar, BBadge, BCardBody, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RoomActionButtons from './RoomActionButtons.vue'
import {
  roomModelField, RoomsLabel, leaseModelField, roomStatus, roomsPaymentFrequency,
} from '@/api/field_model'
import {
  leaseExpiredSvg, overdueSvg, unrentedSvg, upcomingSvg,
} from '@/assets/custom_svg'
import DateLocalMixin from '@/mixins/dateLocalMixin'

// eslint-disable-next-line no-extend-native
Date.prototype.isValid = function () {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  // eslint-disable-next-line no-self-compare
  return this.getTime() === this.getTime()
}
const roomsLabel = new RoomsLabel()
export default {
  components: {
    BCard, BAvatar, BBadge, BCardBody, RoomActionButtons,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [DateLocalMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      roomModelField,
      leaseModelField,
      unrentedSvg,
      leaseExpiredSvg,
    }
  },
  computed: {
    iconStatus() {
      return this.isUnrented ? unrentedSvg : this.value.overDue ? overdueSvg : this.value.dueOn ? upcomingSvg : ''
    },
    iconColor() {
      return this.isUnrented ? 'secondary' : this.value.overDue ? 'danger' : this.value.dueOn ? 'success' : ''
    },
    isUnrented() {
      return this.value[roomModelField.status] === roomStatus.unrented
    },
    lease() {
      return this.value.lease
    },
    getRoomLabel() {
      return roomsLabel.getRoomNoLabel(this.value, {})
    },
    expiredTooltip() {
      return this.value.remainDayUntilExpire <= 0 ? this.value.remainDayUntilExpire === 0 ? 'Expired Today' : `Already expired ${Math.abs(this.value.remainDayUntilExpire)} days ago` : `Expiring after ${Math.abs(this.value.remainDayUntilExpire)} days`
    },
    getRoomPaymentFreq() {
      return !this.isUnrented ? `${roomsLabel.getPaymentFrequencyLabel(this.lease[leaseModelField.paymentFrequency])}(${this.lease[leaseModelField.paymentFrequencyAmount]})` : 'No Lease'
    },
    dueLabel() {
      const roomModel = this.value

      return roomModel[roomModelField.status] === roomStatus.unrented
        ? 'Unrented' : !roomModel.noDue
          ? roomModel.dueYesterday
            ? 'Due Yesterday'
            : roomModel.dueToday
              ? 'Due Today' : roomModel.overDue
                ? roomModel.lease[leaseModelField.paymentFrequency] === roomsPaymentFrequency.oneTime
                  ? `Overdue by ${roomModel.dueNo} day` : `Overdue by ${roomModel.dueNo} days`
                : roomModel.lease[leaseModelField.paymentFrequency] === roomsPaymentFrequency.oneTime
                  ? `Checkout after ${roomModel.dueNo} hours`
                  : `Due On ${roomModel.dueNo} days` : 'No Due'
    },
    dueDateLabel() {
      return new Date(this.value.dueDate).isValid() ? this.formatDateToEt(this.value.dueDate) : 'No due date'
    },
  },
}
</script>

<style>
 .divider-r {
    height: 2px;
    width: 22px;
    background: #ebe9f1;
  }
</style>

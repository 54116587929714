const amharic = {
  weekdays: {
    shorthand: [
      'ሰኞ',
      'ማግሰኞ',
      'ረቡዕ',
      'ሐሙስ',
      'አርብ',
      'ቅዳሜ',
      'እሁድ'],
    longhand: [
      'ሰኞ',
      'ማግሰኞ',
      'ረቡዕ',
      'ሐሙስ',
      'አርብ',
      'ቅዳሜ',
      'እሁድ',
    ],
  },
  months: {
    shorthand: [
      'መስከረም',
      'ጥቅምት',
      'ኅዳር',
      'ታኅሳስ',
      'ጥር',
      'የካቲት',
      'መጋቢት',
      'ሚያዝያ',
      'ግንቦት',
      'ሰኔ',
      'ኃምሌ',
      'ነሐሴ',
      'ጷጉሜ',
    ],
    longhand: [
      'መስከረም',
      'ጥቅምት',
      'ኅዳር',
      'ታኅሳስ',
      'ጥር',
      'የካቲት',
      'መጋቢት',
      'ሚያዝያ',
      'ግንቦት',
      'ሰኔ',
      'ኃምሌ',
      'ነሐሴ',
      'ጷጉሜን',
    ],
  },
  // daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  daysInMonth: [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 31],
  firstDayOfWeek: 1,
  ordinal: nth => {
    const s = nth % 100
    if (s > 3 && s < 21) return 'th'
    switch (s % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  },
  rangeSeparator: ' to ',
  weekAbbreviation: 'Wk',
  scrollTitle: 'Scroll to increment',
  toggleTitle: 'Click to toggle',
  amPM: ['AM', 'PM'],
  yearAriaLabel: 'Year',
  monthAriaLabel: 'Month',
  hourAriaLabel: 'Hour',
  minuteAriaLabel: 'Minute',
  time_24hr: false,
}

// eslint-disable-next-line import/prefer-default-export
export { amharic }

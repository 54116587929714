<template>
  <div class="d-flex align-items-center mt-2 match-height">
    <b-button
      v-if="!isUnrented && !isViewOnly"
      variant="success"
      block
      @click="$emit('logPayment')"
    >
      Log payment
    </b-button>

    <b-button
      v-if="isUnrented && !isViewOnly"
      block
      @click="$emit('addNewLease')"
    >
      Add New Lease
    </b-button>

    <b-dropdown
      variant="link"
      dropleft
      no-caret
    >

      <template
        #button-content
      >
        <b-button
          v-b-tooltip.hover.top="'More options'"
          class="btn-icon"
        >
          <feather-icon icon="MoreHorizontalIcon" />
        </b-button>
      </template>
      <b-dropdown-item
        @click="$emit('showRoomExpense')"
      >
        <span class="align-middle ml-50">Expense</span>

      </b-dropdown-item>
      <b-dropdown-item @click="$emit('showRoomHistory')">
        <span class="align-middle ml-50">Room History</span>
      </b-dropdown-item>
      <b-dropdown-divider />

      <b-dropdown-item @click="$emit('showRoomDetail')">
        <span class="align-middle ml-50">Detail</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isViewOnly"
        @click="$emit('addEditRoom')"
      >
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Edit Room Info</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isUnrented && !isViewOnly"
        @click="$emit('updateExistingLease')"
      >
        <feather-icon
          icon="EditIcon"
        />
        <span class="align-middle ml-50">Update existing lease</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isViewOnly"
        @click="$emit('addNewLease')"
      >
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Add New Lease</span>
        <span
          v-if="!isUnrented"
          class="align-middle ml-50 text-danger text-muted"
        >Terminate Previous Lease</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isUnrented && !isViewOnly"
        @click="$emit('terminateAndMakeThisRoomAdUnrented')"
      >
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Terminate and make this room as unrented</span>
      </b-dropdown-item>
    </b-dropdown>

  </div>
</template>

<script>
import {
  BButton, VBTooltip, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { roomModelField, roomStatus } from '@/api/field_model'
import AuthMixin from '@/mixins/authMixin'

export default {

  components: {
    BButton, BDropdown, BDropdownItem, BDropdownDivider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [AuthMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    isUnrented() {
      return this.value[roomModelField.status] === roomStatus.unrented
    },
  },
}
</script>

<style scoped>

</style>

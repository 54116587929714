<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <!-- <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              Add Task
            </b-button>
          </div> -->
          <b-tabs
            v-model="tabIndex"
            justified
            @input="onInput"
          >
            <b-tab
              active
            >
              <div slot="title">
                <b-avatar
                  rounded
                  size="35"
                  variant="transparent"
                  v-html="floorSvg"
                />
                <div>Floors</div>
              </div>

            </b-tab>
            <b-tab>
              <div slot="title">
                <b-avatar
                  rounded
                  size="35"
                  variant="transparent"
                  v-html="basementSvg"
                />
                <div>Basements</div>
              </div>
            </b-tab>
            <b-tab>
              <div slot="title">
                <b-avatar
                  rounded
                  size="35"
                  variant="transparent"
                  v-html="groundSvg"
                />
                <div>Grounds</div>
              </div>
            </b-tab>

          </b-tabs>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <b-list-group class="list-group-labels">
              <!-- eslint-disable vue/no-use-v-if-with-v-for -->
              <!-- floors -->
              <b-list-group-item
                v-for="(floor, index) in numberOfFloors"
                v-if="tabIndex === 0"
                :key="index"
                button
                :class="roomsApiParameter.floorNo === floor ? `btn-secondary` : null"
                block
                @click="changeFloorNo(floor)"
              >
                Floor {{ floor }}
              </b-list-group-item>

              <!-- basements -->
              <b-list-group-item
                v-for="(basement, index) in numberOfBasement"
                v-if="tabIndex === 1"
                :key="index"
                button
                :class="roomsApiParameter.basementNo === basement ? `btn-secondary` : null"
                block
                @click="changeBasementNo(basement)"
              >
                Basement - {{ basement }}
              </b-list-group-item>

              <!-- ground -->
              <b-list-group-item
                v-if="tabIndex === 2"
                button
                block
                @click="changeGroundNo(ground)"
              />
            <!-- eslint-enable -->
            </b-list-group>

            <!-- Filters -->
            <!-- <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group> -->

            <!-- Tags -->
            <!-- <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Tags
              </h6>
              <feather-icon icon="PlusIcon" />
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.title"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group> -->

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BListGroup, BListGroupItem,
  BTabs, BTab, BAvatar,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapState } from 'vuex'
import { floorSvg, basementSvg, groundSvg } from '@/assets/custom_svg'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    BAvatar,
    BTabs,
    BTab,
  },
  data() {
    return {
      floorSvg,
      basementSvg,
      groundSvg,
      tabIndex: 0,
    }
  },
  computed: {
    ...mapState({
      roomsApiParameter: state => state.rooms.roomsApiParameter,
    }),
    ...mapGetters('currentProperty', ['numberOfFloors', 'numberOfGroundNo',
      'numberOfBasement']),
  },
  created() {
    // if(this.$route.meta.tabIndex) {

    // }
    this.tabIndex = 2
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onInput(v) {
      store.commit('rooms/RESET_STATE', null)
      if (v === 2) {
        this.changeGroundNo(0)
      }
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    getData() {
      const payload = {
        notify: this.showToast,
      }
      this.$store.dispatch('rooms/getRooms', payload)
        .then(() => { })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    changeFloorNo(v) {
      this.$store.commit('rooms/RESET_STATE', null)
      const roomsApiParameter = { ...this.roomsApiParameter }
      roomsApiParameter.floorNo = v
      store.commit('rooms/CHANGE_ROOM_API_PARAMETER', roomsApiParameter)
      this.getData()
    },
    changeBasementNo(v) {
      store.commit('rooms/RESET_STATE', null)
      const roomsApiParameter = { ...this.roomsApiParameter }
      roomsApiParameter.basementNo = v
      store.commit('rooms/CHANGE_ROOM_API_PARAMETER', roomsApiParameter)
      this.getData()
    },
    changeGroundNo(v) {
      store.commit('rooms/RESET_STATE', null)
      const roomsApiParameter = { ...this.roomsApiParameter }
      roomsApiParameter.floorNo = v
      roomsApiParameter.isGround = true
      store.commit('rooms/CHANGE_ROOM_API_PARAMETER', roomsApiParameter)
      this.getData()
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>

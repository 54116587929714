<template>
  <b-sidebar
    id="payment-history-page"
    :visible="isPaymentHistoryPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-close-on-backdrop
    no-header
    right
    @change="(val) => $emit('update:is-payment-history-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Payment Log
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <add-edit-payment-page
        ref="addEditPaymentRef"
        :is-add-edit-payment-sidebar-active.sync="isAddEditPaymentSidebarActive"
        :is-edit="true"
      />
      <b-card
        no-body
        class="mb-0"
      >
        <b-overlay
          id="payment-overlay-background"
          :show="isLoading"
          variant="blur"
          :opacity="0.5"
          blur="2px"
          rounded="sm"
        >
          <b-card
            class="m-1"
            no-body
          >
            <b-card-body class="p-0">

              <section class="todo-application-2">

                <div class="content-todooo">

                  <div style="height: inherit">

                    <div class="todo-app-list">
                      <div class="todo-task-list-wrapper list-group scroll-area">

                        <ul class="todo-task-list media-list">
                          <payment-card
                            v-for="(payment, index) in payments"
                            :key="keyChange + '_' + index"
                            :value="payment"
                            @onClick="onClick"
                          />
                        </ul>
                      </div>
                    </div>

                  </div>

                </div>

              </section>
            </b-card-body>
          </b-card>
        </b-overlay>

        <div class="mx-2 mb-2">

          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BCard, BCol, BRow, BPagination, BOverlay, BCardBody, BSidebar,

} from 'bootstrap-vue'
import { mapState } from 'vuex'
import PaymentCard from './components/PaymentCard.vue'
import AddEditPaymentPage from '../AddEditPayment/AddEditPaymentPage.vue'

import { LogicStateStatus } from '@/libs/ajax'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { kFakeNumber } from '@/assets/helpers'
import { defaultRoomModel } from '@/api/defaultModel'
import {
  commonModelField,
  HistoryLabel, roomHistoriesModelField,
} from '@/api/field_model'
import DateLocalMixin from '@/mixins/dateLocalMixin'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BCard,
    BCardBody,
    AddEditPaymentPage,
    PaymentCard,
    BSidebar,
  },
  mixins: [DateLocalMixin],
  model: {
    prop: 'isPaymentHistoryPageSidebarActive',
    event: 'update:is-payment-history-page-sidebar-active',
  },
  props: {
    isPaymentHistoryPageSidebarActive: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      keyChange: 0,
      isAddEditPaymentSidebarActive: false,
      perPage: 13,
      roomModel: { ...defaultRoomModel },
      roomId: kFakeNumber,
      dataToBeEdited: null,
    }
  },
  computed: {
    from() {
      return this.perPage * (this.currentPage - 1) + (this.payments.length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.payments.length
    },
    of() {
      return this.total
    },
    ...mapState({
      payments: state => state.payments.payments,
      total: state => state.payments.total,
      isLoading: state => state.payments.logicStateStatus === LogicStateStatus.loading,
    }),
  },
  watch: {
    currentPage() {
      this.getPayments()
    },
  },
  methods: {
    onClick(value) {
      this.$refs.addEditPaymentRef.paymentPeriods = this.roomModel.paymentPeriod.map(o => ({ title: this.formatDateToEt(o.date), value: o.paymentId }))
      const historyModel = { ...value }
      // historyModel[roomHistoriesModelField.type] = {
      //   title: new HistoryLabel().getHistoryTypeLabel(historyModel[roomHistoriesModelField.type]),
      //   value: historyModel[roomHistoriesModelField.type],
      // }
      // historyModel[roomHistoriesModelField.action] = {
      //   title: new HistoryLabel().getActionLabel(historyModel[roomHistoriesModelField.action]),
      //   value: historyModel[roomHistoriesModelField.action],
      // }
      // historyModel[roomHistoriesModelField.paymentDate] = {
      //   value: historyModel[commonModelField.id],
      //   title: historyModel[roomHistoriesModelField.paymentDate],
      // }
      this.$refs.addEditPaymentRef.historyModel = value
      this.$refs.addEditPaymentRef.paymentAction = {
        title: new HistoryLabel().getActionLabel(historyModel[roomHistoriesModelField.action]),
        value: historyModel[roomHistoriesModelField.action],
      }
      this.$refs.addEditPaymentRef.paymentType = {
        title: new HistoryLabel().getHistoryTypeLabel(historyModel[roomHistoriesModelField.type]),
        value: historyModel[roomHistoriesModelField.type],
      }
      this.$refs.addEditPaymentRef.paymentDate = {
        value: historyModel[commonModelField.id],
        title: historyModel[roomHistoriesModelField.paymentDate],
      }
      this.isAddEditPaymentSidebarActive = true
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    getPayments() {
      const payload = {
        page: this.currentPage,
        propId: this.propId,
        roomId: this.roomId,
        notify: this.showToast,
      }
      this.$store.dispatch('payments/getPaymentHistory', payload)
        .then(() => { this.keyChange += 1 })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo-2.scss";
#payment-history-page {
  width: 600px;
}
</style>

<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <add-edit-room-page
      ref="addEditRoomRef"
      :is-add-edit-room-sidebar-active.sync="isAddEditRoomSidebarActive"
    />
    <AddEditPaymentPage
      ref="addEditPaymentRef"
      :is-add-edit-payment-sidebar-active.sync="isAddEditPaymentSidebarActive"
    />

    <AddEditLeasePage
      ref="addEditLeaseRef"
      :is-add-edit-lease-sidebar-active.sync="isAddEditLeaseSidebarActive"
    />

    <RoomDetail
      ref="roomDetailRef"
      :room-model="roomModel"
      :is-room-detail-sidebar-active.sync="isRoomDetailSidebarActive"
    />

    <GenerateRooms
      ref="generateModalRef"
    />

    <RoomExpensePage
      ref="roomExpensePageRef"
      :room-id="roomId"
      :is-room-expense-page-sidebar-active.sync="isRoomExpensePageSidebarActive"
    />

    <PaymentHistoryPage
      ref="paymentHistoryRef"
      :is-payment-history-page-sidebar-active.sync="isPaymentHistoryPageSidebarActive"
    />

    <TerminateDeleteModal
      ref="terminateAndMakeThisRoomAdUnrentedModalRef"
      button-text="Terminate"
      title="END"
      @terminate="onTerminateAndMakeThisRoomAdUnrented"
    />

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <RoomSearchBarHeader
        v-if="!simpleVersion"
        @mqShallShowLeftSidebar="mqShallShowLeftSidebar = true"
        @generate="handleGenerateRooms(true)"
      />

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >

        <ul
          class="todo-task-list media-list"
        >
          <li
            class="todo-item li"
            style="text-align: center;"
          >
            <b-overlay
              id="reminder-overlay-background"
              :show="isLoading"
              variant="blur"
              :opacity="0.5"
              blur="2px"
            >
              <template #overlay>
                <b-card
                  class="text-center"
                  style="margin-top: 6rem;"
                >
                  <b-spinner label="Loading..." />
                  <p id="cancel-label">
                    Fetching Rooms data Please wait...
                  </p>
                </b-card>
              </template>
              <b-row v-if="isNotEmpty">

                <!-- User Info: Left col -->
                <b-col
                  v-for="(item, index) in rooms"
                  :key="index"
                  cols="21"
                  xl="6"
                  class="d-flex justify-content-between flex-column"
                >
                  <b-overlay
                    id="room-overlay-background"
                    :show="roomLoading === item.id"
                    variant="blur"
                    :opacity="0.5"
                    blur="2px"
                  >
                    <RoomCard
                      :value="item"
                      @showRoomExpense="onShowRoomExpense(item)"
                      @showRoomHistory="onShowRoomHistory(item)"
                      @showRoomDetail="onShowRoomDetail(item)"
                      @addEditRoom="onAddEditRoom(item)"
                      @updateExistingLease="onUpdateExistingLease(item)"
                      @addNewLease="onAddNewLease(item)"
                      @logPayment="onLogPayment(item)"
                      @terminateAndMakeThisRoomAdUnrented="terminateAndMakeThisRoomAdUnrented(item)"
                    />
                  </b-overlay>
                </b-col>
              </b-row>
              <b-card
                v-if="!simpleVersion && (!isNotEmpty && !isLoading)"
                style="font-weight: bold !important; font-size: 16px;"
              >
                <div v-if="isSuccess && totalNumberOfUnits === 0">

                  <b-card-text>No room added, Click generate room to get started</b-card-text>
                  <b-button

                    @click="handleGenerateRooms(false)"
                  >
                    Generate Rooms
                  </b-button>
                </div>
                <span v-if="isStatusNone && !isGround">👈 Select Room Number First</span>
              </b-card>
            </b-overlay>

            <b-button
              v-if="rooms.length > 0"
              :variant="loadNoMore ? 'secondary' : 'primary'"
              :disabled="loadNoMore"
              @click="loadMore"
            >
              {{ loadNoMore ? 'Load No more' : 'Load more' }}
            </b-button>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </div>

    <portal
      v-if="!simpleVersion"
      to="content-renderer-sidebar-left"
    >
      <RoomLeftSideBar
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />

    </portal>
  </div>

</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
// import draggable from 'vuedraggable'
import {
  ref,
} from '@vue/composition-api'
import {
  BCol, BRow, BOverlay, BButton, BCard, BCardText, BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import Zemen from 'zemen'
import TerminateDeleteModal from '../AddEditLease/components/TerminateDeleteModal.vue'
import PaymentHistoryPage from '../PaymentHistory/PaymentHistoryPage.vue'
import RoomExpensePage from './components/RoomExpensePage.vue'
import AddEditLeasePage from '../AddEditLease/AddEditLeasePage.vue'
import AddEditPaymentPage from '../AddEditPayment/AddEditPaymentPage.vue'
import RoomDetail from './components/RoomDetail.vue'
import AddEditRoomPage from '../AddEditRoom/AddEditRoomPage.vue'
import GenerateRooms from './components/GenerateRooms.vue'
import RoomCard from './components/RoomCard.vue'
import RoomSearchBarHeader from './components/RoomSearchBarHeader.vue'
import RoomLeftSideBar from './components/RoomLeftSideBar.vue'
import { LogicStateStatus } from '@/libs/ajax'
import {
  commonModelField, leaseModelField, roomHistoriesModelField, roomModelField, RoomsLabel, roomsPaymentFrequency, roomStatus,
} from '@/api/field_model'
import { kFakeNumber } from '@/assets/helpers'
import { defaultLeaseModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import DateLocalMixin from '@/mixins/dateLocalMixin'

export default {
  components: {
    VuePerfectScrollbar,

    // draggable,
    RoomLeftSideBar,
    RoomSearchBarHeader,
    RoomCard,
    BCol,
    BOverlay,
    BRow,
    BSpinner,
    BCard,
    BCardText,
    GenerateRooms,
    AddEditRoomPage,
    AddEditPaymentPage,
    RoomDetail,
    AddEditLeasePage,
    RoomExpensePage,
    PaymentHistoryPage,
    BButton,
    TerminateDeleteModal,
  },
  mixins: [DateLocalMixin],
  props: {
    simpleVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAddEditRoomSidebarActive: false,
      isAddEditPaymentSidebarActive: false,
      isRoomDetailSidebarActive: false,
      isAddEditLeaseSidebarActive: false,
      isRoomExpensePageSidebarActive: false,
      isPaymentHistoryPageSidebarActive: false,
      roomId: kFakeNumber,
      roomModel: {},
      toBeTerminated: null,
      roomLoading: false,
    }
  },
  computed: {
    ...mapState({
      rooms: state => state.rooms.rooms,
      isSuccess: state => state.rooms.logicStateStatus === LogicStateStatus.success || state.rooms.logicStateStatus === LogicStateStatus.loadNoData,
      isStatusNone: state => state.rooms.logicStateStatus === LogicStateStatus.none,
      loadNoMore: state => state.rooms.logicStateStatus === LogicStateStatus.loadNoData,
      isNotEmpty: state => state.rooms.rooms.length > 0,
      roomsApiParameter: state => state.rooms.roomsApiParameter,
      isGround: state => state.rooms.roomsApiParameter.isGround === true,
      isLoading: state => state.rooms.logicStateStatus === LogicStateStatus.loading,
      totalNumberOfUnits: state => state.rooms.totalNumberOfUnits,
    }),
  },
  mounted() {
    this.$store.commit('rooms/RESET_STATE')
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    getData() {
      const payload = {
        notify: this.showToast,
      }
      this.$store.dispatch('rooms/getRooms', payload)
        .then(() => { })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    loadMore() {
      this.getData()
    },
    handleGenerateRooms(update) {
      if (this.isStatusNone && !this.isGround) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Info',
            icon: 'InfoIcon',
            text: 'Please select Floor, or basement No First.',
            variant: 'info',
          },
        },
        {
          timeout: 2500,
          position: 'bottom-right',
        })
      } else {
        this.$refs.generateModalRef.update = update
        this.$refs.generateModalRef.$refs.inputModalRef.$refs['input-model'].show()
      }
    },
    onAddEditRoom(v) {
      this.isAddEditRoomSidebarActive = true
      this.$refs.addEditRoomRef.roomModel = v
    },
    onShowRoomExpense(v) {
      this.roomId = v.id
      this.isRoomExpensePageSidebarActive = true
      this.$store.commit('expenses/UPDATE_EXPENSES', [])
      this.$refs.roomExpensePageRef.$refs.expensePageRef.getExpenses()
    },
    onShowRoomHistory(v) {
      this.$refs.paymentHistoryRef.roomId = v.id
      this.$refs.paymentHistoryRef.roomModel = { ...v }
      this.$store.commit('payments/UPDATE_PAYMENTS', [])
      this.$refs.paymentHistoryRef.getPayments()
      this.isPaymentHistoryPageSidebarActive = true
    },
    onShowRoomDetail(v) {
      this.roomModel = v
      this.isRoomDetailSidebarActive = true
    },
    onUpdateExistingLease(v) {
      const lM = { ...v.lease }
      lM[leaseModelField.paymentFrequency] = {
        title: new RoomsLabel().getPaymentFrequencyLabel(lM[leaseModelField.paymentFrequency]),
        value: lM[leaseModelField.paymentFrequency],
      }
      const start = new Date(lM[leaseModelField.startDate])
      const end = new Date(lM[leaseModelField.leaseEnd])
      this.$refs.addEditLeaseRef.startDateEt = this.convertToEthiopian([start.getFullYear(), start.getMonth() + 1, start.getDate()])
      this.$refs.addEditLeaseRef.leaseEndEt = this.convertToEthiopian([end.getFullYear(), end.getMonth() + 1, end.getDate()])
      // lM[leaseModelField.paymentFrequencyAmount] = {
      //   title: `${lM[leaseModelField.paymentFrequencyAmount]}`,
      //   value: lM[leaseModelField.paymentFrequencyAmount],
      // }
      this.$refs.addEditLeaseRef.leaseModel = lM
      this.$refs.addEditLeaseRef.paymentFrequencyAmount = {
        title: `${lM[leaseModelField.paymentFrequencyAmount]}`,
        value: lM[leaseModelField.paymentFrequencyAmount],
      }
      this.$refs.addEditLeaseRef.lastLeaseEnd = lM[leaseModelField.leaseEnd]
      this.$refs.addEditLeaseRef.roomModel = { ...v }
      this.$refs.addEditLeaseRef.isEdit = true
      this.$refs.addEditLeaseRef.previousLeaseId = null
      this.$refs.addEditLeaseRef.terminateAndAdd = false
      this.isAddEditLeaseSidebarActive = true
    },
    onAddNewLease(v) {
      const lM = { ...defaultLeaseModel }
      lM[commonModelField.roomId] = v.id
      // start and end lease
      const zare = new Zemen()
      this.$refs.addEditLeaseRef.startDateEt = [zare.getFullYear(), zare.getMonth(), zare.getDate()]
      this.$refs.addEditLeaseRef.leaseEndEt = [zare.getFullYear(), zare.getMonth(), zare.getDate()]

      lM[leaseModelField.paymentFrequency] = {
        title: new RoomsLabel().getPaymentFrequencyLabel(roomsPaymentFrequency.monthly),
        value: roomsPaymentFrequency.monthly,
      }
      // lM[leaseModelField.paymentFrequencyAmount] = {
      //   title: '1',
      //   value: 1,
      // }
      this.$refs.addEditLeaseRef.leaseModel = lM
      this.$refs.addEditLeaseRef.paymentFrequencyAmount = {
        title: '1',
        value: 1,
      }

      this.$refs.addEditLeaseRef.lastLeaseEnd = null
      this.$refs.addEditLeaseRef.isEdit = false
      this.$refs.addEditLeaseRef.roomModel = { ...v }

      // determine if it is terminate
      if (v[roomModelField.status] !== roomStatus.unrented) {
        this.$refs.addEditLeaseRef.terminateAndAdd = true
        this.$refs.addEditLeaseRef.previousLeaseId = v.lease.id
      } else {
        this.$refs.addEditLeaseRef.previousLeaseId = null
        this.$refs.addEditLeaseRef.terminateAndAdd = false
      }

      this.isAddEditLeaseSidebarActive = true
    },
    onLogPayment(v) {
      const pR = { ...this.$refs.addEditPaymentRef.historyModel }
      const paymentPeriods = v.paymentPeriod.map(o => ({ title: this.formatDateToEt(o.date), value: o.paymentId }))
      this.$refs.addEditPaymentRef.paymentPeriods = paymentPeriods

      pR[commonModelField.roomId] = v.id
      pR[commonModelField.tenantId] = v.lease.tenant !== null ? v.lease.tenant.id : kFakeNumber
      pR[commonModelField.leaseId] = v.lease.id
      pR[roomHistoriesModelField.amount] = v.lease[leaseModelField.rentAmount]
      // pR[roomHistoriesModelField.paymentDate] = paymentPeriods.length > 0 ? paymentPeriods[0] : null
      this.$refs.addEditPaymentRef.historyModel = pR
      this.$refs.addEditPaymentRef.paymentDate = paymentPeriods.length > 0 ? paymentPeriods[0] : null
      this.isAddEditPaymentSidebarActive = true
    },
    terminateAndMakeThisRoomAdUnrented(v) {
      this.toBeTerminated = v
      this.$refs.terminateAndMakeThisRoomAdUnrentedModalRef.$refs['terminate-delete-model'].show()
    },
    onTerminateAndMakeThisRoomAdUnrented() {
      const terminateData = { ...this.toBeTerminated }
      this.toBeTerminated = null
      this.roomLoading = terminateData.id
      terminateData.notify = this.showToast
      this.$store.dispatch('addEditLease/terminateLease', terminateData)
        .then(() => {
          this.roomLoading = false
        })
        .catch(error => {
          this.showToast('Error', error.message)
          this.roomLoading = false
        })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      perfectScrollbarSettings,

      // UI
      isTaskHandlerSidebarActive,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>

<template>
  <b-sidebar
    v-if="show"
    id="add-edit-lease-sidebar"
    :visible="isAddEditLeaseSidebarActive"
    bg-variant="white"
    no-close-on-backdrop
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-lease-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ terminateAndAdd ? 'Terminate And Add New' : isEdit ? 'Update Lease' : 'Add New Lease' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <SelectTenantPage
        ref="tenantsPageRef"
        :is-select-tenant-page-sidebar-active.sync="isSelectTenantPageSidebarActive"
        @select="onTenantSelect"
      />

      <b-alert
        variant="warning"
        :show="shouldDisable()"
      >
        <div class="alert-body">
          <span>The Start date, payment Frequency should usually not need to change if you have made payment before.</span>
        </div>
      </b-alert>

      <TerminateDeleteModal
        ref="terminateDeleteModalRef"
        :button-text="buttonText"
        :title="title"
        @terminate="onTerminate"
        @delete="deleteLease"
      />

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Date of rent"
                label-for="date-of-rent"
              >
                <ethiopiaDatePicker
                  v-show="isEthiopianCalender"
                  v-model="startDateEt"
                  @change="onStartDateEtChange"
                />
                <flat-pickr
                  v-show="!isEthiopianCalender"
                  v-model="leaseModel[leaseModelField.startDate]"
                  :disabled="shouldDisable()"
                  class="form-control"
                />
              </b-form-group>

            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Rent(Lease) end date"
                label-for="date-of-lease-end"
              >
                <ethiopiaDatePicker
                  v-show="isEthiopianCalender"
                  v-model="leaseEndEt"
                  @change="onLeaseEndEtChange"
                />
                <flat-pickr
                  v-show="!isEthiopianCalender"
                  v-model="leaseModel[leaseModelField.leaseEnd]"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Rent Amount"
            label-for="rentAmount"
          >
            <b-form-input
              v-model="leaseModel[leaseModelField.rentAmount]"
              autofocus
              trim
              number
              type="number"
              placeholder="0.0"
            />
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Payment Frequency"
                label-for="paymentFrequency"
              >
                <v-select
                  id="paymentFrequency"
                  v-model="leaseModel[leaseModelField.paymentFrequency]"
                  label="title"
                  taggable
                  :clearable="false"
                  :disabled="shouldDisable()"
                  label-for="paymentFrequency"
                  placeholder="Payment Frequency"
                  :options="paymentFrequency"
                  @input="onPaymentFrequencyChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="How Frequent?"
                label-for="howFrequent"
              >
                <v-select
                  id="howFrequent"
                  v-model="paymentFrequencyAmount"
                  label="title"
                  label-for="howFrequent"
                  taggable
                  :clearable="false"
                  :disabled="shouldDisable()"
                  placeholder="How Frequent?"
                  :options="paymentFrequencyAmountList"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <app-collapse
            accordion
            type="border"
          >
            <app-collapse-item title="Contract(Lease)">
              <b-form-group
                label="Other Images"
                label-for="other-images"
              >
                <b-row v-if="!isEdit || (isEdit && (!leaseModel[leaseModelField.contractImages] || (leaseModel[leaseModelField.contractImages] && leaseModel[leaseModelField.contractImages].length <= 0)))">

                  <b-col
                    cols="12"
                  >
                    <al-upload
                      v-model="contractImages"
                      :action="`${baseURL}/global/uploadImage`"
                      :simultaneous-uploads="5"
                      :max="5"
                      @file-success="onContractImageUploaded"
                    />

                  </b-col>
                </b-row>
                <b-col
                  v-else
                  class="p-0"
                >
                  <b-img
                    v-for="(item, index) in leaseModel[leaseModelField.contractImages]"
                    :key="index"
                    v-bind="imageMainProps"
                    :src="item"
                  />
                  <al-upload
                    v-if="leaseModel[leaseModelField.contractImages].length < 5"
                    ref="upload"
                    v-model="contractImages"
                    :action="`${baseURL}/global/uploadImage`"
                    :max="(5 - leaseModel[leaseModelField.contractImages])"
                    @file-success="onContractImageUploaded"
                  >
                    <al-upload-file
                      v-for="(file, i) in contractImages"
                      :key="i"
                      :file="file"
                    />
                    <al-upload-btn :multiple="true">
                      <div>
                        <b-button
                          v-if="contractImages.length <= 0"
                          block
                          class="mt-1"
                        >
                          Change Image
                        </b-button>
                      </div>
                    </al-upload-btn>
                  </al-upload>
                </b-col>
              </b-form-group>
            </app-collapse-item>
          </app-collapse>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center mt-1 cursor-pointer"
            style="border-radius: 10px;"
            @click="selectTenant"
          >
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  rounded
                  size="42"
                  v-html="tenantSvg"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="mb-0">
                  {{ (leaseModel.tenant && leaseModel.tenant.id && leaseModel.tenant.id !== -9999) ? leaseModel.tenant[tenantModelField.fullName] : 'Select Tenant' }}
                </h6>
                <small v-if="(leaseModel.tenant && leaseModel.tenant.id)">{{ leaseModel.tenant[tenantModelField.phone] || '~phone~' }}</small>
                <small v-else>Tenant information</small>
              </b-media-body>
            </b-media>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-1"
              :color="(leaseModel.tenant && leaseModel.tenant.id) ? 'var(--dark)' : 'var(--success)'"
              size="16"
            />
          </b-list-group-item>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isLoading"
              class="mr-2"
              type="submit"
            >
              {{ terminateAndAdd ? 'Terminate And Add New' : isEdit ? 'Update Lease' : 'Add New Lease' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

          </div>
          <b-button
            v-if="isEdit"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            class="mt-1"
            :disabled="isLoading"
            variant="danger"
            @click="showDeleteModal"
          >
            Delete Lease
          </b-button>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BCol, BRow, BAlert, BListGroupItem, BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import { nextTick, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Zemen from 'zemen'
import EthiopiaDatePicker from '../../components/ethiopiaDatePicker.vue'
import SelectTenantPage from '../Tenants/SelectTenantPage.vue'
import TerminateDeleteModal from './components/TerminateDeleteModal.vue'
import AlUpload from '../../components/upload/AlUpload.vue'
import {
  leaseModelField, RoomsLabel, roomsPaymentFrequency, tenantModelField,
} from '@/api/field_model'
import { defaultLeaseModel, defaultRoomModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { LogicStateStatus } from '@/libs/ajax'
import { baseURL } from '@/libs/axios'
import { generatedDatesFn } from '../../assets/generateDates'
import { tenantSvg } from '@/assets/custom_svg'
import DateLocalMixin from '@/mixins/dateLocalMixin'

const roomLabel = new RoomsLabel()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,

    BButton,
    BCol,
    BRow,
    BAlert,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,

    ValidationObserver,
    AlUpload,
    vSelect,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    TerminateDeleteModal,
    SelectTenantPage,
    EthiopiaDatePicker,
  },
  directives: {
    Ripple,
  },
  mixins: [DateLocalMixin],
  model: {
    prop: 'isAddEditLeaseSidebarActive',
    event: 'update:is-add-edit-lease-sidebar-active',
  },
  props: {
    isAddEditLeaseSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      leaseModelField,
      tenantModelField,
      baseURL,
      tenantSvg,
      imageMainProps: {
        style: 'height: 140px;width:140px;',
        fluid: true,
        thumbnail: true,
      },
      isSelectTenantPageSidebarActive: false,
      contractImages: [],
      paymentFrequency: roomLabel.paymentFrequencyList(),
      paymentFrequencyAmount: {
        title: '1',
        value: 1,
      },
      paymentFrequencyAmountList: [...Array(12).keys()].map(o => ({ value: o + 1, title: `${o + 1}` })),
      lastLeaseEnd: null,
      buttonText: '',
      title: '',
      previousLeaseId: null,
      isEdit: false,
      terminateAndAdd: false,
      roomModel: { ...defaultRoomModel },
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.addEditLease.logicStateStatus === LogicStateStatus.loading,
    }),
    paymentFrequencyW() {
      return this.leaseModel[leaseModelField.paymentFrequency]
    },
  },

  methods: {
    onStartDateEtChange(v) {
      this.startDateEt = v
    },
    onLeaseEndEtChange(v) {
      this.leaseEndEt = v
    },
    onPaymentFrequencyChange(v) {
      switch (v.value) {
        case roomsPaymentFrequency.monthly:
          this.paymentFrequencyAmountList = [...Array(11).keys()].map(o => ({ value: o + 1, title: `${o + 1}` }))
          break
        case roomsPaymentFrequency.weekly:
          this.paymentFrequencyAmountList = [...Array(4).keys()].map(o => ({ value: o + 1, title: `${o + 1}` }))
          break
        case roomsPaymentFrequency.yearly:
          this.paymentFrequencyAmountList = [...Array(10).keys()].map(o => ({ value: o + 1, title: `${o + 1}` }))
          break
        case roomsPaymentFrequency.oneTime:
          this.paymentFrequencyAmountList = []
          this.paymentFrequencyAmount = {
            title: '1',
            value: 1,
          }
          break

        default:
          break
      }
    },
    shouldDisable() {
      return this.isEdit && this.roomModel.hasPaymentMadeBefore
    },
    onContractImageUploaded(v) {
      this.leaseModel[leaseModelField.contractImages] = v.response.data.url
      this.contractImages = []
    },
    onTerminate() {
      this.onSubmit(true)
    },
    showDeleteModal() {
      this.buttonText = 'Delete'
      this.title = 'DELETE'
      this.$refs.terminateDeleteModalRef.$refs['terminate-delete-model'].show()
    },
    selectTenant() {
      this.isSelectTenantPageSidebarActive = true
      this.$refs.tenantsPageRef.$refs.tenantsPageRef.getTenants()
    },
    onTenantSelect(v) {
      this.leaseModel.tenant = v
    },
    deleteLease() {
      const leaseModel = { ...this.leaseModel }
      const payload = {
        leaseModel,
        notify: this.showToast,
      }
      this.$store.dispatch('addEditLease/deleteLease', payload)
        .then(() => {
          this.reset()
          this.$emit('update:is-add-edit-lease-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    onSubmit(continuee) {
      const leaseModel = { ...this.leaseModel }

      if (this.isEthiopianCalender) {
        leaseModel[leaseModelField.startDate] = Zemen.toGC(this.startDateEt.join('-'))
        leaseModel[leaseModelField.leaseEnd] = Zemen.toGC(this.leaseEndEt.join('-'))
      }

      if (this.lastLeaseEnd && this.lastLeaseEnd === leaseModel[leaseModelField.leaseEnd]) {
        this.lastLeaseEnd = null
      }

      leaseModel[leaseModelField.paymentFrequency] = leaseModel[leaseModelField.paymentFrequency].value
      leaseModel[leaseModelField.paymentFrequencyAmount] = this.paymentFrequencyAmount.value

      if (!this.isEdit) {
        leaseModel[leaseModelField.dateType] = this.dateLocalType
        // if (this.isEthiopianCalender) { // new lease
        // }
      }
      // else if (leaseModel[leaseModelField.dateType] === dateLocalTypeField.ethiopian) {
      //   leaseModel[leaseModelField.startDate] = Zemen.toGC(this.startDate)
      //   leaseModel[leaseModelField.leaseEnd] = Zemen.toGC(this.leaseEnd)
      // }

      const payload = {
        leaseModel,
        notify: this.showToast,
      }

      if (this.isEdit) {
        payload.hasPaymentMadeBefore = this.roomModel.hasPaymentMadeBefore
        payload.lastLeaseEnd = this.roomModel.paymentPeriod.length > 0
          ? this.roomModel.paymentPeriod.slice(-1)[0].date : this.lastLeaseEnd
        this.updateLease(payload)
        return
      }

      if (this.terminateAndAdd && !continuee) {
        this.buttonText = 'Terminate'
        this.title = 'END'
        this.$refs.terminateDeleteModalRef.$refs['terminate-delete-model'].show()
        return
      }

      const dates = generatedDatesFn(
        new Date(leaseModel[leaseModelField.startDate]),
        new Date(Date.now()),
        leaseModel[leaseModelField.paymentFrequency],
        this.paymentFrequencyAmount.value,
        new Date(Date.now()),
        true,
      )

      if (leaseModel[leaseModelField.paymentFrequency] === roomsPaymentFrequency.oneTime
        ? dates.length >= 1
        : dates.length > 1) {
        this.$swal({
          title: '',
          text: 'Would you like Yene property to Log all previous payments and bring this tenancy up to date?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            payload.logPreviousPayments = true
            payload.previousLeaseId = this.previousLeaseId
            this.addNewLease(payload)
          } else if (result.dismiss === 'cancel') {
            payload.hasPaymentMadeBefore = false
            payload.previousLeaseId = this.previousLeaseId
            this.addNewLease(payload)
          }
        })
      } else {
        payload.logPreviousPayments = false
        payload.previousLeaseId = this.previousLeaseId
        this.addNewLease(payload)
      }
    },
    addNewLease(payload) {
      this.$store.dispatch('addEditLease/addNewLease', payload)
        .then(() => {
          this.reset()
          this.$emit('update:is-add-edit-lease-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    updateLease(payload) {
      this.$store.dispatch('addEditLease/updateLease', payload)
        .then(() => {
          this.reset()
          this.$emit('update:is-add-edit-lease-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },

    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const leaseModel = { ...defaultLeaseModel }
    const startDateEt = ref([])
    const leaseEndEt = ref([])
    const show = ref(true)

    const reset = () => {
      show.value = false
      startDateEt.value = []
      leaseEndEt.value = []
      nextTick(() => {
        show.value = true
      })
    }

    const resetleaseModel = () => {
      leaseModel.value = JSON.parse(JSON.stringify(defaultLeaseModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetleaseModel, reset)

    return {
      leaseModel,
      show,
      reset,
      refFormObserver,
      getValidationState,
      resetForm,
      startDateEt,
      leaseEndEt,
    }
  },
}
</script>

<style lang="scss">
#add-edit-lease-sidebar {
  width: 700px;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

import { roomsPaymentFrequency } from '@/api/field_model'

export const diffInDate = (d1, d2) => {
  const date1 = d1
  const date2 = d2

  // To calculate the time difference of two dates
  const DifferenceInTime = date2.getTime() - date1.getTime()

  // To calculate the no. of days between two dates
  const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24)
  return Math.floor(DifferenceInDays)
}

export const monthDiff = (d1, d2) => {
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

export const generatePerEveryMonth = (start, end, every, count) => {
  const addMonths = (d, n) => {
    const dt = new Date(d.getTime())
    dt.setMonth(dt.getMonth() + n)
    return dt
  }
  const dates = []
  let temp
  const startDay = start.getDate()

  for (let i = 0; i <= count; i += 1) {
    const year = i === 0 ? start.getFullYear() : dates[dates.length - 1].getFullYear()
    const month = i === 0 ? start.getMonth() : dates[dates.length - 1].getMonth()

    temp = new Date(year, month, startDay)

    if (temp.getDate() !== startDay) temp.setDate(0)
    dates.push(i === 0 ? temp : addMonths(temp, every))
  }
  return dates
}

export const weeksBetween = (d1, d2) => Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000))

/*
for week and day
*/
export const generatePerDates = (startDate, endDate, count, day) => {
  const dates = []
  let currentDate = startDate

  for (let i = 0; i < count; i += 1) {
    currentDate = i === 0
      ? currentDate
      : new Date(currentDate).setDate(new Date(currentDate).getDate() + day)
    dates.push(new Date(currentDate))
  }
  return dates
}

/*
for yearly
*/
export const generateYearly = (startDate, endDate, count) => {
  const dates = []
  let currentDate = startDate

  for (let i = 0; i < count; i += 1) {
    currentDate = i === 0
      ? currentDate
      : new Date(currentDate).setFullYear(
        new Date(currentDate).getFullYear() + 1,
      )
    dates.push(new Date(currentDate))
  }
  return dates
}

export const generatedDatesFn = (
  startDate,
  endDate,
  paymentFrequency,
  paymentFrequencyAmount,
  currentDate,
  minusOne,
) => {
  let generatedDates = []
  let diff
  switch (paymentFrequency) {
    case roomsPaymentFrequency.monthly:
      // diff = Math.round(monthDiff(startDate, endDate) / paymentFrequencyAmount);
      diff = Math.ceil(monthDiff(startDate, endDate) / paymentFrequencyAmount)

      generatedDates = generatePerEveryMonth(
        startDate,
        endDate,
        paymentFrequencyAmount,
        diff,
      )
      // generatedDates = generatedDates.map((v) => ({
      //   date: new Date(v.setDate(startDate.getDate() + 1)),
      // }));

      break
    case roomsPaymentFrequency.weekly:
      diff = Math.ceil(
        weeksBetween(startDate, endDate) / paymentFrequencyAmount,
      ) // 4 / 2 = 2

      generatedDates = generatePerDates(
        startDate,
        endDate,
        diff,
        7 * paymentFrequencyAmount, // 14 Bi-weekly
      )
      break
    case roomsPaymentFrequency.oneTime:
      generatedDates = generatePerDates(startDate, endDate, 1, 1)
      break
    case roomsPaymentFrequency.yearly:
      diff = endDate.getFullYear() - startDate.getFullYear()

      generatedDates = generateYearly(startDate, endDate, diff)
      break

    default:
      break
  }

  generatedDates = generatedDates.map(v => ({
    date: v.date ? v.date : v,
    hasPaid: false,
  }))

  if (minusOne) {
    generatedDates.map(v => {
      if (v.date <= currentDate) {
        // eslint-disable-next-line no-param-reassign
        v.hasPaid = true
      }
      return v
    })
  }

  return generatedDates
}

<template>

  <li
    class="todo-item"
    @click="onClick"
  >
    <div class="todo-title-wrapper">
      <div class="todo-title-area">
        <div class="title-wrapper">
          <!-- <span class="todo-title">{{ value[tenantModelField.fullName] || '~' }}</span> -->
          <b-media
            vertical-align="center"
            class="p-0"
          >
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(value[tenantModelField.fullName])"
                variant="light-primary"
              />
            </template>
            <h6
              class="font-weight-bold d-block text-nowrap mb-0"
            >
              {{ value[tenantModelField.fullName] }}
            </h6>
            <h6 class="text-muted ">
              {{ value[tenantModelField.phone] }}
            </h6>
            <p
              class="text-truncate mb-0"
            >
              {{ value[tenantModelField.email] }}
            </p>
          </b-media>
        </div>

      </div>

      <div
        v-if="value[tenantModelField.phone] && value[tenantModelField.phone].length >0"
        class="todo-item-action"
      >
        <div class="badge-wrapper mr-1" />
        <!-- <small class="text-nowrap text-muted mr-1">{{ value[tenantModelField.phone] }} </small> -->
        <b-avatar
          size="32"
          :href="`tel:${value[tenantModelField.phone]}`"
          variant="success"
        >
          <feather-icon icon="PhoneForwardedIcon" />
        </b-avatar>
      </div>
    </div>
  </li>
</template>

<script>
import { formatDate, avatarText } from '@core/utils/filter'
import { BAvatar, BMedia } from 'bootstrap-vue'
import { tenantModelField } from '@/api/field_model'

export default {
  components: {
    BAvatar,
    BMedia,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      // Filters
      formatDate,
      avatarText,
      tenantModelField,
    }
  },

  methods: {
    onClick() {
      this.$emit('onClick', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

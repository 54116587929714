<template>
  <b-sidebar
    v-if="show"
    id="add-edit-room-sidebar"
    :visible="isAddEditRoomSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-room-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Unit
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-form-group
            label="Security Deposit"
            label-for="security-deposit"
          >
            <b-form-input
              v-model="roomModel[roomModelField.depositAmount]"
              autofocus
              trim
              number
              type="number"
              placeholder="0.0"
            />
          </b-form-group>

          <b-form-group
            label="Area"
            label-for="area"
          >
            <b-form-input
              v-model="roomModel[roomModelField.area]"
              autofocus
              trim
              placeholder="Area"
            />
          </b-form-group>

          <b-form-group
            label="Number of baths"
            label-for="number-of-baths"
          >
            <b-form-input
              v-model="roomModel[roomModelField.numberOfBaths]"
              autofocus
              trim
              placeholder="Number of baths"
            />
          </b-form-group>

          <b-form-group
            label="Number of beds"
            label-for="number-of-beds"
          >
            <b-form-input
              v-model="roomModel[roomModelField.numberOfBeds]"
              autofocus
              trim
              placeholder="Number of beds"
            />
          </b-form-group>

          <b-form-group
            label="Room Images"
            label-for="room-images"
          >
            <b-row v-if="(!roomModel[roomModelField.images] || (roomModel[roomModelField.images] && roomModel[roomModelField.images].length <= 0))">

              <b-col
                cols="12"
              >
                <al-upload
                  v-model="otherImages"
                  :action="`${baseURL}/global/uploadImage`"
                  :simultaneous-uploads="5"
                  :max="5"
                />

              </b-col>
            </b-row>
            <b-col
              v-else
              class="p-0"
            >
              <b-img
                v-for="(item, index) in roomModel[roomModelField.images]"
                :key="index"
                v-bind="imageMainProps"
                :src="item"
              />
              <al-upload
                v-if="roomModel[roomModelField.images].length < 5"
                ref="upload"
                v-model="otherImages"
                :action="`${baseURL}/global/uploadImage`"
                :max="(5 - roomModel[roomModelField.images])"
              >
                <al-upload-file
                  v-for="(file, i) in otherImages"
                  :key="i"
                  :file="file"
                />
                <al-upload-btn :multiple="true">
                  <div>
                    <b-button
                      v-if="otherImages.length <= 0"
                      block
                      class="mt-1"
                    >
                      Change Image
                    </b-button>
                  </div>
                </al-upload-btn>
              </al-upload>
            </b-col>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isLoading"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BCol, BRow, BImg,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import { nextTick, ref } from '@vue/composition-api'
import AlUpload from '../../components/upload/AlUpload.vue'
import AlUploadBtn from '../../components/upload/btn.vue'
import AlUploadFile from '../../components/upload/file.vue'
import { RoomsLabel, roomModelField } from '@/api/field_model'
import { defaultRoomModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { LogicStateStatus } from '@/libs/ajax'
import { baseURL } from '@/libs/axios'

const roomLabel = new RoomsLabel()
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,

    BButton,
    BCol,
    BRow,
    BImg,
    AlUploadBtn,
    AlUploadFile,

    ValidationObserver,
    AlUpload,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddEditRoomSidebarActive',
    event: 'update:is-add-edit-room-sidebar-active',
  },
  props: {
    isAddEditRoomSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      roomModelField,
      roomLabel,
      baseURL,
      imageMainProps: {
        style: 'height: 140px;width:140px;',
        fluid: true,
        thumbnail: true,
      },
      otherImages: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.addEditExpense.logicStateStatus === LogicStateStatus.loading,
    }),
  },
  methods: {
    onSubmit() {
      const roomModel = { ...this.roomModel }
      roomModel.propId = this.$route.query.propId
      if (this.otherImages.length > 0) {
        roomModel[roomModelField.images] = this.otherImages.map(v => v.response.data.url)
      }
      const payload = {
        roomModel,
        notify: this.showToast,
      }
      this.$store.dispatch('addEditRoom/updateRoom', payload)
        .then(() => {
          this.reset()
          this.$emit('update:is-add-edit-room-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const roomModel = { ...defaultRoomModel }
    const show = ref(true)

    const reset = () => {
      show.value = false
      nextTick(() => {
        show.value = true
      })
    }

    const resetroomModel = () => {
      roomModel.value = JSON.parse(JSON.stringify(defaultRoomModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetroomModel)

    return {
      roomModel,
      show,
      reset,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">

</style>

<template>
  <b-sidebar
    id="add-edit-payment-sidebar"
    :visible="isRoomDetailSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    lazy
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-room-detail-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Room detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <b-carousel
        id="carousel-example-generic"
        controls
        class="m-1"
        style="border-radius: 10px;"
        img-height="270"
        indicators
      >
        <b-carousel-slide
          v-for="(item, index) in roomModel.imgs"
          :key="index"
        >
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="270px"
              style="object-fit: fill;height:270px;border-radius: 10px;"
              :src="item"
              alt="room image"
            >
          </template>
        </b-carousel-slide>
      </b-carousel>

      <b-card
        no-body
        class="m-1"
        bg-variant="light-grey"
      >
        <b-card-header>
          <b-card-text>
            <h3>{{ getRoomLabel }}</h3>
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="4">

              <div class="media-body">
                <h6 class="mb-0">
                  {{ roomModel[roomModelField.numberOfBeds] }}
                </h6><small>Bed(s)</small>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="media-body">
                <h6 class="mb-0">
                  {{ roomModel[roomModelField.numberOfBaths] }}
                </h6><small>Bath(s)</small>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="media-body">
                <h6 class="mb-0">
                  {{ roomModel[roomModelField.area] }}
                </h6><small>Sq Ft</small>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card
        v-if="!isUnrented"
        no-body
        class="m-1"
        bg-variant="light-grey"
      >
        <b-card-header>
          <b-card-text>
            <h4 class="section-label">
              TENANT INFORMATION
            </h4>
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="10">

              <b-media
                v-if="roomModel.lease.tenant"
                class="p-0"
              >
                <template #aside>
                  <b-avatar :text="avatarText(roomModel.lease.tenant[tenantModelField.fullName])" />
                </template>
                <h6>{{ roomModel.lease.tenant[tenantModelField.fullName] }}</h6>
                <p>{{ roomModel.lease.tenant[tenantModelField.phone] }}</p>
              </b-media>
            </b-col>
            <b-col cols="2">
              <b-avatar
                :href="`tel:${roomModel.lease.tenant ? roomModel.lease.tenant[tenantModelField.phone] : '00'}`"
                variant="success"
              >
                <feather-icon icon="PhoneCallIcon" />
              </b-avatar>

            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
      <b-card
        v-if="!isUnrented"
        no-body
        class="m-1"
        bg-variant="light-grey"
      >
        <b-card-header>
          <b-card-text>
            <h4 class="section-label">
              LEASE INFORMATION
            </h4>
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col cols="6">

              <b-media class="p-0">

                <h6 class="section-label">
                  {{ getFreqLabel }} RENT
                </h6>
                <h6>Br {{ roomModel.lease ? roomModel.lease[leaseModelField.rentAmount] : '' }}</h6>
              </b-media>
            </b-col>
            <b-col cols="6">
              <b-media class="p-0">

                <h6 class="section-label">
                  OVERDUE RENT
                </h6>
                <h6>{{ !roomModel.overDue ? 'NO OVERDUE' : `Br ${roomModel.amountOverdue}` }}</h6>
              </b-media>
            </b-col>

          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">

              <b-media class="p-0">

                <h6 class="section-label">
                  START DATE
                </h6>
                <h6>{{ roomModel.lease.startDate }}</h6>
              </b-media>
            </b-col>
            <b-col cols="6">
              <b-media class="p-0">

                <h6 class="section-label">
                  END DATE
                </h6>
                <h6>{{ roomModel.lease.leaseEnd }}</h6>
              </b-media>
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BCarousel, BCarouselSlide, BSidebar, BCard, BCardText, BCardHeader, BCardBody, BCol, BRow, BMedia, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { defaultRoomModel } from '@/api/defaultModel'
import {
  leaseModelField, roomModelField, RoomsLabel, roomStatus, tenantModelField,
} from '@/api/field_model'

const roomsLabel = new RoomsLabel()

export default {
  components: {
    BCarousel,
    BSidebar,
    BCarouselSlide,
    BCard,
    BCardText,
    BCardBody,
    BAvatar,
    BCol,
    BMedia,
    BRow,
    BCardHeader,
  },
  model: {
    prop: 'isRoomDetailSidebarActive',
    event: 'update:is-room-detail-sidebar-active',
  },
  props: {
    isRoomDetailSidebarActive: {
      type: Boolean,
      required: true,
    },
    roomModel: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      roomModelField,
      leaseModelField,
      tenantModelField,
      avatarText,
    }
  },
  computed: {
    getRoomLabel() {
      return roomsLabel.getRoomNoLabel(this.roomModel, {})
    },
    isUnrented() {
      return this.roomModel[roomModelField.status] === roomStatus.unrented
    },
    getFreqLabel() {
      return roomsLabel.getPaymentFrequencyLabel( this.roomModel.lease ? this.roomModel.lease[leaseModelField.paymentFrequency] : '')
    },
  },
}
</script>

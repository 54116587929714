<template>
  <b-sidebar
    id="select-tenant-page-sidebar"
    :visible="isSelectTenantPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    no-close-on-backdrop
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-select-tenant-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Select Tenant
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <tenants-page
        ref="tenantsPageRef"
        :selectable="true"
        @select="onSelect"
      />
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'
import TenantsPage from './TenantsPage.vue'

export default {
  components: {
    BSidebar, TenantsPage,
  },
  model: {
    prop: 'isSelectTenantPageSidebarActive',
    event: 'update:is-select-tenant-page-sidebar-active',
  },
  props: {
    isSelectTenantPageSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onSelect(v) {
      this.$emit('select', v)
      this.$emit('update:is-select-tenant-page-sidebar-active', false)
    },
  },
}
</script>

<style>
#select-tenant-page-sidebar {
  width: 460px;
}
</style>

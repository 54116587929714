<template>
  <b-sidebar
    v-if="show"
    id="add-new-expense-sidebar"
    :visible="isAddNewExpenseSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-expense-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ !isEdit ? 'Add New Expense' : 'Update Expense' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Total Amount -->
          <validation-provider
            #default="validationContext"
            name="Total amount"
            rules="required"
          >
            <b-form-group
              label="Total amount"
              label-for="total-amount"
            >
              <b-form-input
                v-model="expenseModel[expenseModelField.amount]"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="0.0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Date Due -->
          <b-form-group
            label="Due date"
            label-for="due-date"
          >
            <flat-pickr
              v-model="expenseModel[expenseModelField.dueDate]"
              class="form-control"
            />
          </b-form-group>

          <!-- Category -->
          <b-form-group
            label="Category"
            label-for="Category"
          >

            <v-select
              v-model="category"
              label="title"
              label-for="Category"
              placeholder="Select Category"
              :options="expenseCategories"
            />
          </b-form-group>

          <b-form-group>
            <b-form-textarea
              v-model="expenseModel[expenseModelField.note]"
              placeholder="Notes"
              rows="3"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div
            v-if="!isViewOnly"
            class="d-flex mt-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isLoading"
              class="mr-2"
              type="submit"
            >
              {{ isEdit ? 'Update': 'Add' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

          </div>
          <b-button
            v-if="isEdit && !isViewOnly"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            block
            class="mt-1"
            :disabled="isLoading"
            variant="danger"
            @click="onDelete"
          >
            Delete
          </b-button>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapState } from 'vuex'
import { nextTick, ref } from '@vue/composition-api'
import { ExpenseCategory, expenseModelField } from '@/api/field_model'
import { defaultExpenseModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { LogicStateStatus } from '@/libs/ajax'
import { kFakeNumber } from '@/assets/helpers'
import AuthMixin from '@/mixins/authMixin'

const expenseCategory = new ExpenseCategory()
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  model: {
    prop: 'isAddNewExpenseSidebarActive',
    event: 'update:is-add-new-expense-sidebar-active',
  },
  props: {
    isAddNewExpenseSidebarActive: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    propId: {
      type: Number,
      default: kFakeNumber,
    },
    roomId: {
      type: Number,
      default: kFakeNumber,
    },
  },
  data() {
    return {
      required,
      expenseCategory,
      expenseModelField,
      expenseCategories: expenseCategory.expenseCategories(),
      category: null,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.addEditExpense.logicStateStatus === LogicStateStatus.loading,
    }),
  },
  watch: {
    value(v) {
      if (this.isEdit) {
        this.expenseModel = { ...v }
        if (this.category && this.category.length > 0) {
          this.expenseModel[expenseModelField.category] = {
            value: this.category,
            title: expenseCategory.getLabel(this.category),
          }
        }
      }
    },
  },
  methods: {
    onDelete() {
      const payload = {
        expenseModel: this.expenseModel,
        notify: this.showToast,
      }
      this.$store.dispatch('addEditExpense/deleteExpense', payload)
        .then(() => {
          this.$emit('update:is-add-new-expense-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    onSubmit() {
      const expenseModel = { ...this.expenseModel }
      expenseModel[expenseModelField.category] = this.category ? this.category.value : null
      if (!this.isEdit) {
        expenseModel.propId = this.propId
        expenseModel.roomId = this.roomId
      }

      const payload = {
        expenseModel,
        notify: this.showToast,
      }
      this.$store.dispatch(`addEditExpense/${this.isEdit ? 'updateExpense' : 'addExpense'}`, payload)
        .then(() => {
          this.reset()
          this.$emit('update:is-add-new-expense-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const expenseModel = { ...defaultExpenseModel }
    const show = ref(true)

    const reset = () => {
      show.value = false
      nextTick(() => {
        show.value = true
      })
    }

    const resetexpenseModel = () => {
      expenseModel.value = JSON.parse(JSON.stringify(defaultExpenseModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetexpenseModel)

    return {
      expenseModel,
      show,
      reset,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-expense-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

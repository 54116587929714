<template>
  <b-sidebar
    id="room-expense-page"
    :visible="isRoomExpensePageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-close-on-backdrop
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-room-expense-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Room Expenses
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <ExpensesPage
        ref="expensePageRef"
        :room-id="roomId"
      />
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar,
} from 'bootstrap-vue'
import ExpensesPage from '../../Expenses/ExpensesPage.vue'
import { kFakeNumber } from '@/assets/helpers'

export default {
  components: {
    BSidebar, ExpensesPage,
  },
  model: {
    prop: 'isRoomExpensePageSidebarActive',
    event: 'update:is-room-expense-page-sidebar-active',
  },
  props: {
    isRoomExpensePageSidebarActive: {
      type: Boolean,
      required: true,
    },
    roomId: {
      type: Number,
      default: kFakeNumber,
    },
  },
}
</script>

<style lang="scss">
#room-expense-page {
  width: 700px;
}
</style>

<template>
  <b-modal
    id="terminate-prevent-closing"
    ref="terminate-delete-model"
    :title="`Type &quot;${title}&quot; to terminate`"
    :ok-title="buttonText"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="labelState"
        label-for="label-input"

        :invalid-feedback="invalidFeedBack"
      >
        <b-form-input
          id="label-input"
          v-model="label"
          :state="labelState"
          required
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BModal, BFormGroup, BFormInput,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      invalidFeedBack: '',
      label: '',
      labelState: null,
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity() && this.label.toUpperCase() === this.title
      this.labelState = valid
      if (!valid) {
        this.invalidFeedBack = `Please type the word "${this.title}"`
      }

      return valid
    },
    resetModal() {
      this.label = ''
      this.secondInvalidFeedBack = ''
      this.invalidFeedBack = ''
      this.labelState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$emit(this.title === 'END' ? 'terminate' : 'delete')

      this.resetModal()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['terminate-delete-model'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style lang="scss" scoped>

#terminate-prevent-closing {
  .form-control {
    text-align: center;
  }
}
</style>

<template>

  <li
    class="todo-item"
    @click="onClick"
  >
    <div class="todo-title-wrapper">
      <div class="todo-title-area">
        <div class="title-wrapper">

          <!-- <span class="todo-title mr-0 ml-0">{{ category || '~' }}</span> -->
          <b-media
            vertical-align="center"
            class="p-0"
          >
            <template #aside>
              <b-avatar
                :variant="isPaymentUncompleted ? 'danger' : 'success'"
              >
                <feather-icon
                  size="26"
                  color="var(--white)"
                  :icon="isPaymentUncompleted ? 'XIcon' : 'CheckIcon'"
                />
              </b-avatar>
            </template>
            <h6
              class="todo-title ml-0"
            >
              {{ category || '~' }}
            </h6>
            <p class="text-muted ">
              {{ formatDateToEt(value.createdAt) }}
            </p>

          </b-media>
        </div>

      </div>

      <div class="todo-item-action">
        <b-media
          vertical-align="right"
          class="p-0"
        >
          <h6>
            {{ value[roomHistoriesModelField.amount] }}
          </h6>
          <small class="text-nowrap text-muted mr-1">{{ formatDateToEt(value[roomHistoriesModelField.paymentDate]) }}</small>
        </b-media>

      </div>
    </div>

  </li>
</template>

<script>
import { formatDate, avatarText } from '@core/utils/filter'
import { BMedia, BAvatar } from 'bootstrap-vue'
import { HistoryLabel, roomHistoriesModelField } from '@/api/field_model'
import DateLocalMixin from '@/mixins/dateLocalMixin'

const historyLabel = new HistoryLabel()

export default {
  components: {
    BMedia, BAvatar,
  },
  mixins: [DateLocalMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      // Filters
      formatDate,
      avatarText,
      roomHistoriesModelField,
    }
  },
  computed: {
    category() {
      return historyLabel.getHistoryTypeLabel(this.value[roomHistoriesModelField.type])
    },
    isPaymentUncompleted() {
      return this.value[roomHistoriesModelField.action] === historyLabel.paymentIncomplete
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

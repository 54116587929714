<template>
  <div class="app-fixed-search d-flex align-items-center">

    <jump-modal
      ref="jumpModel"
      @selected="onSearchInputChange"
    />

    <!-- Toggler -->
    <div class="sidebar-toggle d-block d-lg-none ml-1">
      <feather-icon
        icon="MenuIcon"
        size="21"
        class="cursor-pointer"
        @click="$emit('mqShallShowLeftSidebar')"
      />
    </div>

    <!-- Searchbar -->
    <div class="d-flex align-content-center justify-content-between w-100">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon
            icon="SearchIcon"
            class="text-muted"
          />
        </b-input-group-prepend>
        <b-form-input
          v-model="searchQuery"
          placeholder="Room name, Tenant Info"
        />
      </b-input-group>
    </div>

    <!-- Dropdown -->
    <div
      class="dropdown"
      style="display: flex;"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <b-avatar
            v-b-tooltip.hover.bottom="'Filter'"
            rounded
            size="30"
            variant="transparent"
            style="fill: var(--secondary);"
            v-html="filterSvg"
          >
            <!-- <feather-icon
                  size="16"
                  :svg="filterSvg"
                  class="text-white"
                /> -->
          </b-avatar>

        </template>
        <b-dropdown-item @click="handleFilter('overDue')">
          <span class="text-danger">Overdue</span>
        </b-dropdown-item>
        <b-dropdown-item @click="handleFilter('dueOn')">
          <span class="text-warning">Upcoming</span>
        </b-dropdown-item>
        <b-dropdown-item @click="handleFilter('unrented')">
          <span class="text-secondary">Unrented</span>
        </b-dropdown-item>

      </b-dropdown>
      <b-avatar
        v-b-tooltip.hover.bottom="'Jump To Room'"
        rounded
        class="p-0 ml-2"
        size="30"
        button
        variant="transparent"
        style="fill: var(--secondary);"
        @click="showJumpModal"
        v-html="jumpSvg"
      >
        <!-- <feather-icon
                  size="16"
                  :svg="filterSvg"
                  class="text-white"
                /> -->
      </b-avatar>

      <b-avatar
        v-if="!isViewOnly"
        v-b-tooltip.hover.bottom="'Generate More Rooms'"
        rounded
        size="30"
        variant="transparent"
        style="fill: var(--secondary);"
        class="p-0 ml-2"
        button
        @click="$emit('generate')"
        v-html="generateSvg"
      >
        <!-- <span
          style="width: 21px; height: 21px; fill: var(--white) !important;"

        /> -->
      </b-avatar>

      <b-avatar
        v-b-tooltip.hover.bottom="'Reset'"
        rounded
        style="fill: var(--secondary);"
        size="30"
        class="p-0 ml-2 mr-1"
        button
        @click="reset"
      >
        <feather-icon icon="RotateCwIcon" />
      </b-avatar>

    </div>

  </div>
</template>

<script>
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import JumpModal from './JumpModal.vue'
import { jumpSvg, filterSvg, generateSvg } from '@/assets/custom_svg'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { debounce } from '@/assets/helpers'
import { LogicStateStatus } from '@/libs/ajax'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BAvatar, JumpModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [AuthMixin],
  props: {
    mqShallShowLeftSidebar: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      jumpSvg,
      filterSvg,
      generateSvg,
      searchQuery: '',
    }
  },
  computed: {
    ...mapState({
      roomsApiParameter: state => state.rooms.roomsApiParameter,
      isStatusNone: state => state.rooms.logicStateStatus === LogicStateStatus.none,
      isGround: state => state.rooms.roomsApiParameter.isGround === true,
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    searchQuery: debounce(function (val) {
      if (this.isStatusNone && !this.isGround) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Info',
            icon: 'InfoIcon',
            text: 'Please select Floor, or basement No First.',
            variant: 'info',
          },
        },
        {
          timeout: 2500,
          position: 'bottom-right',
        })
      } else {
        const thiss = this
        if (val && val !== '') {
          thiss.onSearchInputChange(val, null)
        } else {
          thiss.reset()
        }
      }
    }, 500),
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    getData() {
      const payload = {
        notify: this.showToast,
      }
      this.$store.dispatch('rooms/getRooms', payload)
        .then(() => { })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },

    showJumpModal() {
      this.$refs.jumpModel.$refs['jump-model'].show()
    },
    reset() {
      this.$store.commit('rooms/RESET_FILTER')
      const roomsApiParameter = { ...this.roomsApiParameter }
      roomsApiParameter.page = 1
      roomsApiParameter.query = ''
      roomsApiParameter.jumpNo = null
      this.$store.commit('rooms/CHANGE_ROOM_API_PARAMETER', roomsApiParameter)
      this.getData()
    },
    handleFilter(type) {
      this.$store.commit('rooms/RESET_FILTER')
      const roomsApiParameter = { ...this.roomsApiParameter }
      roomsApiParameter.filter[type] = true
      roomsApiParameter.page = 1
      this.$store.commit('rooms/CHANGE_ROOM_API_PARAMETER', roomsApiParameter)
      this.getData()
    },
    onSearchInputChange(query, jumpNo) {
      const roomsApiParameter = { ...this.roomsApiParameter }
      roomsApiParameter.page = 1
      roomsApiParameter.query = query
      roomsApiParameter.jumpNo = jumpNo
      this.$store.commit('rooms/CHANGE_ROOM_API_PARAMETER', roomsApiParameter)
      const payload = {
        notify: this.showToast,
      }
      this.$store.dispatch('rooms/searchRoom', payload)
        .then(() => { })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style scoped>

</style>

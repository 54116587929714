<template>
  <b-modal
    id="modal-prevent-closing"
    ref="input-model"
    title="How many?"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="qtyState"
        label-for="qty-input"

        :invalid-feedback="invalidFeedBack"
      >
        <b-form-input
          id="qty-input"
          v-model="qty"
          :state="qtyState"
          required
          type="number"
          min="1"
          number
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BModal, BFormGroup, BFormInput,
  },
  data() {
    return {
      invalidFeedBack: '',
      qty: 0,
      qtyState: null,
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity() && this.qty > 0
      this.qtyState = valid
      if (!valid) {
        this.invalidFeedBack = 'Invalid Quantity'
      }

      return valid
    },
    resetModal() {
      this.qty = 0
      this.secondInvalidFeedBack = ''
      this.invalidFeedBack = ''
      this.qtyState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$emit('onInput', this.qty)
      this.resetModal()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['input-model'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style lang="scss" scoped>

#modal-prevent-closing {
  .form-control {
    text-align: center;
  }
}
</style>

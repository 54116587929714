<template>
  <b-modal
    id="modal-scrollable"
    ref="jump-model"
    scrollable
    title="Select To Jump"
    cancel-title="Close"
    ok-title="Submit"
    hide-footer
    cancel-variant="outline-secondary"
  >
    <b-list-group
      vertical="sm"
      class="mt-1"
      block
    >
      <b-row>
        <b-col
          v-for="(item, index) in totalNumberOfUnits"
          :key="index"
          cols="3"
          style="margin-top: 0.5rem;"
        >
          <b-list-group-item
            button
            @click="handleSelect(item)"
          >
            {{ getRoomName(item) }}
          </b-list-group-item>
        </b-col>
      </b-row>
    </b-list-group>
  </b-modal>
</template>

<script>
import {
  BModal, BListGroup, BListGroupItem, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'
import { roomModelField, RoomsLabel } from '@/api/field_model'
import { kFakeNumber } from '@/assets/helpers'

export default {
  components: {
    BModal, BListGroup, BListGroupItem, BRow, BCol,
  },
  computed: {
    ...mapGetters('rooms', ['totalNumberOfUnits']),
    ...mapState({
      roomsApiParameter: state => state.rooms.roomsApiParameter,
    }),
  },
  methods: {
    getRoomName(item) {
      return new RoomsLabel().getRoomNoLabel(this.roomModel(), { no: item, ignoreCustomName: true })
    },
    roomModel() {
      return {
        // eslint-disable-next-line no-nested-ternary
        [roomModelField.floorNo]: this.roomsApiParameter.floorNo !== null
          ? this.roomsApiParameter.floorNo
          : this.roomsApiParameter.isGround
            ? 0
            : kFakeNumber,
        customName: '',
        [roomModelField.basementNo]:
                      this.roomsApiParameter.basementNo !== null ? this.roomsApiParameter.basementNo : kFakeNumber,
      }
    },
    handleSelect(v) {
      this.$emit('selected', null, v)
      this.$nextTick(() => {
        this.$refs['jump-model'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <RoomsPage :simple-version="true" />
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import RoomsPage from '../Rooms/RoomsPage.vue'

export default {
  components: { RoomsPage },
  data() {
    return {
      isLeaseEnd: this.$route.query.isLeaseEnd,
      isOverdue: this.$route.query.isOverdue,
      isUnrented: this.$route.query.isUnrented,
    }
  },
  computed: {
    ...mapState({
      roomsApiParameter: state => state.rooms.roomsApiParameter,
    }),
  },
  mounted() {
    this.$store.commit('rooms/RESET_STATE', null)
    const roomsApiParameter = { ...this.roomsApiParameter }
    const filter = { ...roomsApiParameter.filter }

    filter.overDue = this.isOverdue
    filter.unrented = this.isUnrented
    filter.leaseEnd = this.isLeaseEnd
    roomsApiParameter.filter = filter

    this.$store.commit('rooms/CHANGE_ROOM_API_PARAMETER', roomsApiParameter)
    const payload = {
      notify: this.showToast,
    }
    this.$store.dispatch('rooms/getRooms', payload)
      .then(() => { })
      .catch(error => {
        this.showToast('Error', error.message)
      })
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
  },
}
</script>

<style scoped lang="scss">
  .todo-application {
    .content-area-wrapper {
      .content-right {
        width: 100% !important;
      }
    }
  }
</style>

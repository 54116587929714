import { userRoleType, usersModelField } from '@/api/field_model'
import UserAllowed from '@/auth/userAllowed'

const AuthMixin = {
  computed: {
    companyInfo() {
      return this.$store.state.auth.companyInfo
    },
    userData() {
      return this.$store.state.auth.AppActiveUser
    },
    isAdmin() {
      return !!(this.userData && this.userData[usersModelField.role] === userRoleType.admin)
    },
    userId() {
      return this.userData ? this.userData.id : null
    },
    userAllowedClass() {
      return new UserAllowed()
    },
    isReportAllowed() {
      return new UserAllowed().isReportAllowed(this.userData ? this.userData[usersModelField.allowed] : [])
    },
    isAddPropertyAllowed() {
      return new UserAllowed().isAddPropertyAllowed(this.userData ? this.userData[usersModelField.allowed] : [])
    },
    isEditPropertyAllowed() {
      return new UserAllowed().isEditPropertyAllowed(this.userData ? this.userData[usersModelField.allowed] : [])
    },
    allowedPropertyId() {
      // eslint-disable-next-line no-nested-ternary
      return !this.isAdmin
        ? (this.userData ? this.userData[usersModelField.allowedPropertyId] : null)
        : null
    },
    isViewOnly() {
      return this.userData && this.userData[usersModelField.role] === userRoleType.onlyView
    },
  },

}

export default AuthMixin

<template>
  <b-sidebar
    v-if="show"
    id="add-new-tenant-sidebar"
    :visible="isAddNewTenantSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-tenant-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ !isEdit ? 'Add New Tenant' : 'Update Tenant' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-form-group
            label="Tenant Images"
            label-for="images"
          >
            <b-row
              class="pb-1"
            >
              <b-col
                cols="12"
                md="6"
              >
                <div
                  v-if="!isEdit"
                  class="upload-view-custom-main"
                >
                  <al-upload
                    ref="upload"
                    v-model="nationIdFrontImage"
                    :action="`${baseURL}/global/uploadImage`"
                    :max="1"
                  >
                    <div class="clear-fix">
                      <al-upload-file
                        v-for="(file, i) in nationIdFrontImage"
                        :key="i"
                        :file="file"
                      />
                      <al-upload-btn :multiple="false">
                        <div>
                          <i>＋</i>
                          <p>Front ID</p>
                        </div>
                      </al-upload-btn>
                    </div>
                  </al-upload>
                </div>
                <b-col
                  v-else
                  class="p-0 m-0"
                >
                  <b-img
                    v-bind="imageMainProps"
                    :src="nationIdFrontImageSrc()"
                  />

                  <al-upload
                    v-if="!isViewOnly"
                    ref="upload"
                    v-model="nationIdFrontImage"
                    :action="`${baseURL}/global/uploadImage`"
                    :max="1"
                    @file-success="onNationalIdFrontUploaded"
                  >
                    <al-upload-file
                      v-for="(file, i) in nationIdFrontImage"
                      :key="i"
                      :file="file"
                    />
                    <al-upload-btn :multiple="false">
                      <div>
                        <b-button
                          v-if="nationIdFrontImage.length <= 0"
                          block
                          class="mt-1"
                        >
                          Change Image
                        </b-button>
                      </div>
                    </al-upload-btn>
                  </al-upload>

                </b-col>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >

                <div
                  v-if="!isEdit"
                  class="upload-view-custom-main"
                >
                  <al-upload
                    v-if="!isViewOnly"
                    ref="upload2"
                    v-model="nationIdBackImage"
                    :max="1"
                    :action="`${baseURL}/global/uploadImage`"
                  >
                    <div class="clear-fix">
                      <al-upload-file
                        v-for="(file, i) in nationIdBackImage"
                        :key="i"
                        :file="file"
                      />
                      <al-upload-btn :multiple="false">
                        <div>
                          <i>＋</i>
                          <p>Back ID</p>
                        </div>
                      </al-upload-btn>
                    </div></al-upload>
                </div>
                <b-col v-else>
                  <b-img

                    v-bind="imageMainProps"
                    :src="nationIdBackImageSrc()"
                  />
                  <al-upload
                    v-if="!isViewOnly"
                    ref="upload"
                    v-model="nationIdBackImage"
                    :action="`${baseURL}/global/uploadImage`"
                    :max="1"
                    @file-success="onNationalIdBackUploaded"
                  >
                    <al-upload-file
                      v-for="(file, i) in nationIdBackImage"
                      :key="i"
                      :file="file"
                    />
                    <al-upload-btn :multiple="false">
                      <div>
                        <b-button
                          v-if="nationIdBackImage.length <= 0"
                          block
                          class="mt-1"
                        >
                          Change Image
                        </b-button>
                      </div>
                    </al-upload-btn>
                  </al-upload>
                </b-col>

              </b-col>
            </b-row>
          </b-form-group>

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="full-name"
            >
              <b-form-input
                v-model="tenantModel[tenantModelField.fullName]"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Tenant Full Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Phone Number"
            label-for="phone-number"
          >
            <b-form-input
              v-model="tenantModel[tenantModelField.phone]"
              autofocus
              trim
              placeholder="Enter Tenant Phone Number"
            />

          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              v-model="tenantModel[tenantModelField.email]"
              autofocus
              trim
              placeholder="Enter Tenant Email"
            />

          </b-form-group>

          <b-form-group
            label="Other Images"
            label-for="other-images"
          >
            <b-row v-if="!isEdit || (isEdit && tenantModel[tenantModelField.otherFiles].length <= 0)">

              <b-col
                v-if="!isViewOnly"
                cols="12"
              >
                <al-upload

                  v-model="otherImages"
                  :action="`${baseURL}/global/uploadImage`"
                  :simultaneous-uploads="5"
                  :max="5"
                  @file-success="onOtherImageUploaded"
                />

              </b-col>
            </b-row>
            <b-col
              v-else
              class="p-0"
            >
              <b-img
                v-for="(item, index) in tenantModel[tenantModelField.otherFiles]"
                :key="index"
                v-bind="imageMainProps"
                :src="item"
              />
              <al-upload
                v-if="tenantModel[tenantModelField.otherFiles].length < 5 && !isViewOnly"
                ref="upload"
                v-model="otherImages"
                :action="`${baseURL}/global/uploadImage`"
                :max="(5 - tenantModel[tenantModelField.otherFiles].length)"
                @file-success="onOtherImageUploaded"
              >
                <al-upload-file
                  v-for="(file, i) in otherImages"
                  :key="i"
                  :file="file"
                />
                <al-upload-btn :multiple="true">
                  <div>
                    <b-button
                      v-if="otherImages.length <= 0"
                      block
                      class="mt-1"
                    >
                      Change Image
                    </b-button>
                  </div>
                </al-upload-btn>
              </al-upload>
            </b-col>
          </b-form-group>

          <!-- Form Actions -->
          <div
            v-if="!isViewOnly"
            class="d-flex mt-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :disabled="isLoading"
              class="mr-2"
              type="submit"
            >
              {{ isEdit ? 'Update': 'Add' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

          </div>
          <b-button
            v-if="isEdit && !isViewOnly"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            block
            class="mt-1"
            :disabled="isLoading"
            variant="danger"
            @click="onDelete"
          >
            Delete
          </b-button>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCol, BRow, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import { nextTick, ref } from '@vue/composition-api'
import AlUpload from '../../../components/upload/AlUpload.vue'
import AlUploadBtn from '../../../components/upload/btn.vue'
import AlUploadFile from '../../../components/upload/file.vue'
import { tenantModelField } from '@/api/field_model'
import { defaultTenantModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { LogicStateStatus } from '@/libs/ajax'
import { baseURL } from '@/libs/axios'
import { noImage } from '@/assets/helpers'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,

    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,
    BImg,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    AlUpload,
    AlUploadBtn,
    AlUploadFile,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTenantSidebarActive',
    event: 'update:is-add-new-tenant-sidebar-active',
  },
  props: {
    isAddNewTenantSidebarActive: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      nationIdFrontImage: [],
      nationIdBackImage: [],
      otherImages: [],
      imageMainProps: {
        // width: 140,
        // height: 140,
        style: "height: 140px;width:140px;",
        fluid: true,
        thumbnail: true
      },
      baseURL,
      tenantModelField,
    }
  },
  mixins: [AuthMixin],
  computed: {
    ...mapState({
      isLoading: state => state.addEditTenant.logicStateStatus === LogicStateStatus.loading,
    }),
    
  },

  methods: {
    nationIdFrontImageSrc() {
      const nI = this.tenantModel[tenantModelField.nationIdFrontImage]
      // return nI && nI.length > 0 ? nI : noImage
      return nI
    },
    nationIdBackImageSrc() {
      const nI = this.tenantModel[tenantModelField.nationIdBackImage]
      // return nI && nI.length > 0 ? nI : noImage
      return nI
    },
    onOtherImageUploaded(v) {
    },
    onNationalIdBackUploaded(v) {
      this.tenantModel[tenantModelField.nationIdBackImage] = v.response.data.url
      this.nationIdBackImage = []
    },
    onNationalIdFrontUploaded(v) {
      this.tenantModel[tenantModelField.nationIdFrontImage] = v.response.data.url
      this.nationIdFrontImage = []
    },
    onDelete() {
      const payload = {
        tenantModel: this.tenantModel,
        notify: this.showToast,
      }
      this.$store.dispatch('addEditTenant/deleteTenant', payload)
        .then(() => {
          this.$emit('update:is-add-new-tenant-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    onSubmit() {
      const tenantModel = { ...this.tenantModel }
      if (this.otherImages.length > 0) {
        tenantModel[tenantModelField.otherFiles] = this.otherImages.map(v => v.response.data.url)
      }
      if (this.nationIdFrontImage.length > 0) {
        tenantModel[tenantModelField.nationIdFrontImage] = this.nationIdFrontImage[0].response.data.url
      }
      if (this.nationIdBackImage.length > 0) {
        tenantModel[tenantModelField.nationIdBackImage] = this.nationIdBackImage[0].response.data.url
      }
      const payload = {
        tenantModel,
        notify: this.showToast,
      }
      this.$store.dispatch(`addEditTenant/${this.isEdit ? 'updateTenant' : 'addTenant'}`, payload)
        .then(() => {
          this.reset()
          this.$emit('update:is-add-new-tenant-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const tenantModel = { ...defaultTenantModel }
    const show = ref(true)

    const reset = () => {
      show.value = false
      nextTick(() => {
        show.value = true
      })
    }

    const resettenantModel = () => {
      tenantModel.value = JSON.parse(JSON.stringify(defaultTenantModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettenantModel)

    return {
      tenantModel,
      show,
      reset,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
#add-new-tenant-sidebar {
  width: 700px;
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.upload-view-custom .content > p,
.upload-view-custom .content > div {
 margin: 20px;
}
.upload-view-custom-main {
	background-color: #fff;
	.al-upload {
		.al-upload-file {
			margin: 0;
			& + .al-upload-btn {
				margin-top: -200px;
				opacity: 0;
			}
		}
		.al-upload-file-def {
			width: 100%;
			height: 100%;
			.alic-wrong {
				display: none;
			}
		}
		.al-upload-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			& > div {
				text-align: center;
			}
			i {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				margin-bottom: 20px;
				font-size: 32px;
				line-height: 1;
				font-style: normal;
				color: #fff;
				background-color: #333;
				border-radius: 50%;
			}
		}
	}
}

.upload-view-custom-main .al-upload .al-upload-file,
.upload-view-custom-main .al-upload .al-upload-btn {
	margin: 0;
	height: 200px;
}

</style>
